import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import Login from "./views/Login"
import config from './config';

export default class App extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loggedIn: localStorage.getItem("token") ? true : false,
        }
    }

    logIn = () => {
        this.setState({loggedIn: true})
    }
    
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <PrivateRoute  path={"/admin"} loggedIn={this.state.loggedIn} render={props => <AdminLayout {...props} />} component={AdminLayout} />
                    <Route exact path={"/login"} name="Login Page" component={(props) => <Login logIn={this.logIn} {...props}/>} />
                    {/* <Route  path="/" name="Login Page" render={props => <AdminLayout {...props} />} /> */}

                    <Redirect from="/" to={"./admin/dispositivos"} />
                </Switch>
            </BrowserRouter>
        )
    }
}
const PrivateRoute = ({loggedIn, ...props }) =>
loggedIn
    ? <Route { ...props } />
    : <Redirect to={"/login"} />


