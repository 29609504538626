import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, withGoogleMap, InfoWindow } from 'react-google-maps';
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import "./Mapa.css"
import HistoricsGraph from 'components/HistoricsGraph/HistoricsGraph';

const Mapa = withGoogleMap(({ markers, sensores }) => {
  const mapRef = useRef(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [sensorSelected, setSensorSelected] = useState(null);


  // This function calculates the center of the map based on the markers.
  const calcularCentroMapa = () => {
    if (markers.length === 0) {
      return { lat: 0, lng: 0 }; // Centro por defecto si no hay marcadores
    } else {


      // Obtener los límites de los marcadores
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend(marker.coords);
      });

      // Calcular el centro de los límites de los marcadores
      const center = {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      };

      return center;
    }
  };

  // This function renders the markers on the map.
  const renderMarkers = () => {
    if (markers.length > 0) {
      return markers.map((marker, index) => (
        <Marker
          key={index}
          position={marker.coords}
          onClick={() => setActiveMarker(marker)}
        />
      ));
    }
  };
  return (
    markers.length > 0 ?
        <GoogleMap
          defaultZoom={12}
          defaultCenter={calcularCentroMapa()} // Centro del mapa inicial
          ref={mapRef}
        >
          <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
            {renderMarkers()}
          </MarkerClusterer>

          {activeMarker && (
            <InfoWindow
              position={activeMarker.coords}
              onCloseClick={() => setActiveMarker(null)}
              options={{
                disableAutoPan: false,
                maxWidth: 800,
                minWidth: 250,
                pixelOffset: new window.google.maps.Size(0, -10),
                zIndex: 9000,
              }}
            >
              <div>

                <div className="titleBuildingMap"><i className="fas fa-home"></i> | {activeMarker.building}</div>
                <div className='boxSensorMap'>

                  {sensorSelected ?
                    <div>
                      <div><strong>Historicos</strong></div>
                      {/* <span className='bg-sm-danger cursorPointer' onClick={() => setSensorSelected(null)}>SALIR</span> */}
                      {/* <button type="button" class="btn btn-danger float-right" onClick={() => setSensorSelected(null)}>volver</button> */}
                      <i className="cursorPointer pull-right fas fa-lg fa-times-circle text-danger" onClick={() => setSensorSelected(null)}></i>
                      <HistoricsGraph id={"historicmap"} sensor={sensorSelected}></HistoricsGraph>
                    </div>:
                  activeMarker.sensors.map((sensor, index) => (
                  <div key={index + 1}>
                    <hr></hr>
                    <div className='boxAttrSensorMap'>
                      <i className="cursorPointer pull-right fas fa-lg fa-chart-line text-info"  onClick={() => setSensorSelected(sensor)}></i>
                      {/* <div onClick={() => setSensorSelected(sensor)}>HISTORICO</div> */}
                      <div className='titleAttrSensorMap'>{sensor._device_type} | {sensor._name} | {sensor._zone} |  {sensor._status >= 0 ? <i className={sensor._status === 0 ? "text-danger fas fa-wifi" : "text-success fas fa-wifi "} /> : null}</div>
                      <div className='attrSensorMap' key={index}>
                        {sensor._state >= 0 ? <div><strong>Estado</strong> = {sensor._state}</div> : null}
                        {sensor._power != null && sensor._power >= 0 ? <div><i className="fas fa-bolt"></i> = {sensor._power}W</div> : null}
                        {sensor._current != null && sensor._current >= 0 ? <div><i className="fas fa-bolt"></i> = {sensor._current}A</div> : null}
                        {sensor._voltage != null && sensor._voltage >= 0 ? <div><i className="fas fa-bolt"></i> = {sensor._voltage}V</div> : null}
                        {sensor._temperature != null ? <div><i className="fas fa-thermometer-half text-success"></i> {sensor._temperature}°C</div> : null}
                        {sensor._humidity != null ? <div><i className="fab fa-empire text-info"></i> {sensor._humidity}</div> : null}
                        {sensor._batteryLevel >= 0 ? <div><i className="fas fa-battery-full text-success" /> {sensor._batteryLevel}%</div> : null}
                        {sensor._tamper == 1 ? <div><i className="fas fa-exclamation-triangle text-warning" /> Fuera de lugar</div> : null}
                      </div>
                    </div>
                  </div>
                  ))}
                </div>

              </div>
            </InfoWindow>
          )}
        </GoogleMap>
       
      : null

  );
});

export default Mapa;
