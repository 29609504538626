import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import GaugeChart from 'react-gauge-chart'

export class PowerGaugeChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }



    render() {
        const porcentage = this.props.value / this.props.max;
        return (

            <div className="card card-stats">
                <div className="content text-center">
                    <Row >

                        <GaugeChart id="gauge-chart1"
                            nrOfLevels={30}//max watt 20000
                            colors={["grey", "#007bb5"]}
                            arcWidth={0.3}
                            textColor={"black"}
                            percent={porcentage}
                            formatTextValue={e => (this.props.value * this.props.scale).toFixed(2).toString()+ this.props.unity}
                            cornerRadius={3}
                            style={{ width: "250px", margin: "auto" }}
                        />
                    </Row>

                    <hr />
                    <Row>
                        {/* <h6> Dipositivos </h6> */}
                        <Col xs={4}>
                            <div className=" text-center icon-warning"><i className=" h4 pe-7s-power  text-success" style={{ fontWeight: "bold", display: "block", marginTop:"0", fontSize:"3rem" }}></i><span className="h2">{this.props.prendidos}</span></div>
                        </Col>
                        <Col xs={4}>
                            <div className=" text-center icon-warning"><i className=" h4 pe-7s-power  text-danger" style={{ fontWeight: "bold", display: "block", marginTop:"0" , fontSize:"3rem"}}></i><span className="h2">{this.props.apagados}</span></div>
                        </Col>
                        <Col xs={4}>
                            <div className=" text-center icon-warning"><i className="h4 pe-7s-power  text-grey" style={{ fontWeight: "bold", display: "block", marginTop:"0", fontSize:"3rem" }}></i><span className="h2">{this.props.fueralinea}</span></div>
                        </Col>


                    </Row>
                    <hr />
                   
             
                </div>
            </div>
        )
    }
}

export default PowerGaugeChart;   