import React, { Component } from 'react'
import "./MobileNav.css"
import {
    NavLink 
} from "react-router-dom";
export default class MobileNav extends Component {

    render() {
        return (
            <div className="MobileNav">
                <NavLink activeClassName={"active-Mobile badge-" + this.props.color} to="/admin/plano"> <i className="pe-7s-map-2"></i></NavLink>
                <NavLink activeClassName={"active-Mobile badge-" + this.props.color} to="/admin/reglas"> <i className="pe-7s-hammer"></i></NavLink>
                <NavLink activeClassName={"active-Mobile badge-" + this.props.color} to="/admin/dispositivos"> <i className="pe-7s-usb"></i></NavLink >
                <NavLink activeClassName={"active-Mobile badge-" + this.props.color} to="/admin/scenes"> <i className="pe-7s-helm"></i></NavLink>
                <NavLink activeClassName={"active-Mobile badge-" + this.props.color} to="/admin/registros"> <i className="pe-7s-note2"></i></NavLink>
            </div>
        )
    }
}
