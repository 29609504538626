import config from "../config"

const urlHistorics = config.baseUrl + "/historics/"
const urlEdificios = config.baseUrl + "/devices/buildings"
const url = config.baseUrl + "/devices/"
const urlLogs = config.baseUrl + "/logs/"
const urlUser = config.baseUrl + "/user/"

///CAMBIA LA CONTRASEÑA DEL USUARIOR///
async function changePassword(user, nuevo, viejo) {
    let respuesta = ""
    await fetch(urlUser + user, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        },
        body: JSON.stringify({ oldPassword: viejo, newPassword: nuevo })
    }).then(response => {
        if (response.status === 500) {
            respuesta = "La contraseña anterior ingresada no es correcta"
        } 
        if (response.status === 200) {
            respuesta = "se guardo correctamente"
        } 
    })
    return respuesta
}


//// TRAE REGISTRO DE ACCIONES COMO LOGS ////
async function traerLogs() {
    const response = await fetch(urlLogs, {
        headers: {
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        }
    })
    return await response.json()
}

//// consulta los sensores disponibles ////
async function traerSensores() {
    const response = await fetch(url, {
        headers: {
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        }
    })
    // if (response.status === 500 || response.status === 403) {
    //     localStorage.removeItem("service")
    //     localStorage.removeItem("token")
    //     localStorage.removeItem("name")
    //     localStorage.removeItem("user")
    //     window.location.reload()
    // } else {
        return await response.json()
    // }
}

//// SE ENVIA UNA ACCION A LOS SENSORES /////
async function postSensor(id, ACTION, DESCRIPTION) {
    // console.log(id + ACTION + DESCRIPTION)
   return await fetch(url + id, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        },
        body: JSON.stringify({ action: ACTION, params: "description " + DESCRIPTION })
    })
    
}

/// SE ACTUALIZA VALORES DE UN SENSOR///
async function patchSensor(atributo, id, valor) {
    await fetch(url + id + "/" + atributo, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        },
        body: JSON.stringify({ value: valor })
    })
}

///// PIDE TOQUEN EN EL LOGIN //
async function pedirToken(user, password, ) {
    if (user !== null && password !== null && user !== "" && password !== "") {
        var body = {
            "username": user,
            "password": password,
        };
        return fetch(config.baseUrl + "/user/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": config.AuthorizationToken
            },
            body: JSON.stringify(body)
        }).then(response => response.json())
    } else {
        return Promise.reject("Debe completar todos los campos")
    }
}


//// consulta los sensores disponibles ////
async function sensorHistoric(path, id,type, attr,from, to) {
    const response = await fetch(urlHistorics+id+"?type="+type+"&attr="+attr+"&from="+from+"&to="+to, {
        headers: {
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token"),
            servicepath: path
        }
    })
    // if (response.status === 500 || response.status === 403) {
    //     localStorage.removeItem("service")
    //     localStorage.removeItem("token")
    //     localStorage.removeItem("name")
    //     localStorage.removeItem("user")
    //     window.location.reload()
    // } else {
        return await response.json()
    // }
}

//// Trae las coordenadas de cada edificio/sucursal/path ////
async function traerUbicacionEdificios() {
    try {
        const response = await fetch(urlEdificios, {
          headers: {
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token"),
          },
        });
    
        // Verificar el estado de la respuesta antes de continuar
        if (response.ok) {
          return await response.json();
        } else {
          // Si el estado de la respuesta no es exitoso, manejar el caso aquí
          if (response.status === 500 || response.status === 403) {
            // localStorage.removeItem("service");
            // localStorage.removeItem("token");
            // localStorage.removeItem("name");
            // localStorage.removeItem("user");
            // window.location.reload();
          } else {
            throw new Error('Error en la respuesta del servidor');
          }
        }
      } catch (error) {
        // Manejar cualquier error que ocurra durante la solicitud fetch
        console.error('Error:', error);
        // Retornar un valor predeterminado o realizar alguna acción alternativa en caso de error
        return null;
      }
}

export { traerSensores, postSensor, pedirToken, patchSensor, traerLogs, changePassword, sensorHistoric, traerUbicacionEdificios }