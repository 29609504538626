import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

export class SimpleCard extends Component {
  render() {
    if (this.props.statsValue.includes("BUTTON") || this.props.statsValue.includes("PIR")) {

    }
    return (
      <div className="card card-stats">
        {this.props.editar}
        <div style={{ padding: "4px 0px 0px 4px", position: "absolute", fontSize: "1.1rem" }}>
          {this.props.linkQuality && this.props.linkQuality >= 0 || this.props.status >= 0 ? (

            <i
              className={
                this.props.status === 0
                  ? "fas fa-wifi text-muted"
                  : this.props.linkQuality === 0
                    ? "fas fa-wifi text-muted"
                    : this.props.linkQuality < 11
                      ? "fas fa-wifi text-danger"
                      : this.props.linkQuality < 41
                        ? "fas fa-wifi text-warning"
                        : this.props.linkQuality
                          ? "fas fa-wifi text-success"
                          : this.props.status > 0
                            ? "fas fa-wifi text-success"
                            : null}
            ></i>
          ) : null}
          {this.props.batteryPercentage >= 0 ? (
            <div >
              <i
                className={
                  this.props.batteryStatus == 2
                    ? "fas fa-plug text-muted"
                    : this.props.batteryPercentage === 0
                      ? "fas fa-battery-quarter text-muted"
                      : this.props.batteryPercentage < 11
                        ? "fas fa-battery-quarter text-danger"
                        : this.props.batteryPercentage < 51
                          ? "fas fa-battery-half text-warning"
                          : this.props.batteryPercentage < 76
                            ? "fas fa-battery-three-quarters text-success"
                            : this.props.batteryPercentage
                              ? "fas fa-battery-full text-success"
                              : null
                }
              ></i>
              <div className="text-muted"><small>{this.props.batteryPercentage}%</small></div>
            </div>
          ) : null}

        </div>
        <div className="content">
          <Row>
            <Col xs={12}>
              <div className="icon-big text-center icon-warning">
                {this.props.bigIcon}
                {this.props.tamper && this.props.tamper == 1 ?(
                  <i className="fas fa-lock-open text-warning" style={{fontSize:"1.5rem"}}></i>
                ) : null}
              </div>
            </Col>
            <Col xs={12}>
              <div className="numbers">

                {this.props.statsValue} {this.props.statsText}
              </div>
            </Col>
          </Row>
          <div className="footer">
            <hr />
            <div className="stats">
              {/* <span> */}
              {this.props.statsIcon} {this.props.statsIconText}
              {this.props.masInfo}
              {/* </span>
              <span> */}


              {/* </span> */}
            </div>
          </div>
          {this.props.historics ? this.props.historics : null}
        </div>
      </div>
    );
  }
}

export default SimpleCard;
