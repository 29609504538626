import React, { Component } from 'react'
import { traerEscenarios, ejecutarEscenario } from '../Services/Escenarios';
import BtnEscenarios from "../components/BtnEscenarios/BtnEscenarios";
import PlanoComponent from "../components/PlanoComponent/PlanoComponent"
import Mapa from '../components/Mapa/Mapa';
export default class Plano extends Component {



    constructor(props) {
        super(props);
        this._isMounted = true;
        this.state = {
            escenarios: [],
            planoFullScreen:false
        };
    }

    componentDidMount() {
      
        traerEscenarios()
            .then(escenarios => {
                if (this._isMounted) {
                    this.setState({ escenarios })}
                }
                )
    }
    componentWillUnmount() {
        this._isMounted = false;
      }
    planoFullScreen(){
    this.setState({planoFullScreen: !this.state.planoFullScreen})
}
    render() {
        return (
            <div style={{ textAlign: "center" }} >
                
                <div className="BoxEscenarios" >
                    {this.state.escenarios?this.state.escenarios.map((escenario, i) => (
                        <BtnEscenarios key={escenario._id} nombre={escenario._name} imagen={escenario._imageUrl} escenario={escenario} ejecutarEscenario={e => ejecutarEscenario(e)} />
                    )):null}
                </div>
                <div className={` ${this.state.planoFullScreen ? 'planoExpanded' : ''}`} style={{display: 'flex'}}>

                <PlanoComponent planoFullScreen={()=>this.planoFullScreen()} />
                </div>
            </div>
        );
    }
}
