
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Particles from 'react-particles-js';
import { Modal, Button } from 'react-bootstrap';

// import avatar from "../../assets/img/faces/face-0.jpg";
// import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import config from '../../config';
import { changePassword } from "../../Services/Services"
import logo from "assets/img/LogoBanner.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      show: false,
      error: "",
      success: ""
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  cerrarSesion() {
    localStorage.removeItem("service")
    localStorage.removeItem("token")
    localStorage.removeItem("name")
    localStorage.removeItem("user")
    this.props.history.push('/login')
  }

   cambiarContrasena(viejo, nuevo, repetir) {
    let user = localStorage.getItem("user")
    if (nuevo === repetir && nuevo !== "" && viejo !== "") {
       changePassword(user, nuevo, viejo).then(resp => {
        if (resp === "La contraseña anterior ingresada no es correcta") {
          this.setState({ error: resp })
        }
        if (resp === "se guardo correctamente") {
          this.setState({ success: resp })
          setTimeout(() => { this.handleClose(); }, 1500)
          setTimeout(() => { this.setState({ success: "" }); }, 1500)


        }
      })
    } else (
      this.setState({ error: "contraseñas no coinciden" })
    )
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    };

    const ModalPassword = <Modal show={this.state.show} onHide={() => this.handleClose()}>
      <Modal.Body>
        <div className="form-group">
        <h3>Cambiar contraseña</h3>
          <label forhtml="form-nombre" >Anterior</label>
          <input id="anterior" onChange={e => this.setState({ user: e.target.value, error: "" })} name="form-nombre" className="form-control" />
        </div>
        <div className="form-group">
          <label forhtml="form-power">Nueva</label>
          <input id="nueva" name="form-power" onChange={e => this.setState({ user: e.target.value, error: "" })} className=" form-control" />
        </div>
        <div className="form-group">
          <label forhtml="form-zona">Repetir Nueva</label>
          <input id="repetir" name="form-zona" onChange={e => this.setState({ user: e.target.value, error: "" })} className=" form-control" />
        </div>
        <div className="text-success h5 bg-success text-center">{this.state.success}</div>
        <div className="text-danger h5 bg-danger text-center">{this.state.error}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-fill btn-danger" onClick={() => this.handleClose()}>CANCELAR</Button>
        <Button className="btn-fill btn-success" onClick={() => this.cambiarContrasena(document.getElementById("anterior").value, document.getElementById("nueva").value, document.getElementById("repetir").value)}>GUARDAR</Button>
      </Modal.Footer>
    </Modal>

    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
        {this.props.hasImage ? (
          <div className="sidebar-background" style={sidebarBackground} />
        ) : (
            null
          )}
        <div className="logo">
          <a
            href={"/admin/plano"}
            className="simple-text text-center logo-normal"
          >
            <div className="logo-img">
              <img src={logo} alt="logo_image" />
            </div>
          </a>
        </div>
        <div className="sidebar-wrapper">
          {/* FONDO EFECTO DE PARTICULAS */}
          <Particles
            height="100vh"
            width="200px"
            style={{ position: "fixed" }}
            params={{
              "particles": {

                "number": {
                  "value": 30
                },
                "density": {
                  "enable": true,
                  "value_area": 5000
                },
                "line_linked": {
                  color: '#fff',
                  shadow: {
                    enable: false,
                    color: "#fff"
                  }
                },
                "color": {
                  "value": "#fff"
                },
                "size": {
                  "value": 1,
                },
                "move": {
                  "enable": true,
                  "speed": 1,
                  "direction": "none",
                  "random": true,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                  }
                },
              },
              "interactivity": {
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "grab"
                  },
                  "onclick": {
                    "enable": true,
                    "mode": "repulse"
                  }
                },
                "modes": {
                  "bubble": {
                    "size": 1,
                    "distance": 10
                  },
                  "repulse": {
                    "distance": 150,
                    "duration": 0.3
                  }
                },
                "resize": true
              },
              "retina_detect": true
            }} />
          <ul className="nav">
            {/* {this.state.width <= 991 ? <AdminNavbarLinks /> : null} */}
            {this.props.routes.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : this.activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              return null;
            })}
          </ul>

          {this.props.setting}

          <div className="userBox">
            <div className="contImg">
              <img alt="user" src={config.baseUrl + "/faces/" + localStorage.getItem("user") + ".jpg"}></img>
              <div onClick={() => this.handleShow()} className="middle">
                <i className=" fa fa-cog icono"></i>
              </div>
            </div>
            <div >Bienvenido!</div>
            <div style={{whiteSpace:"nowrap", textOverflow: "ellipsis", overflow: "hidden", margin:".5rem"}} className="h5">{localStorage.getItem("name")}</div>
            <hr></hr>
            <div className="cursorPointer" onClick={() => this.cerrarSesion()}><i className="  pe-7s-power" ></i> <span>Cerrar Sesión</span></div>
          </div>

        </div>
        {ModalPassword}

      </div>
    );
  }
}

export default Sidebar;
