import React from 'react'
import { Query, Builder, BasicConfig, Utils as QbUtils, Widgets } from 'react-awesome-query-builder';
const { FieldCascader } = Widgets;

const conditionsConfig = {
    ...BasicConfig,
    settings: {
        renderField: (props) => <FieldCascader {...props} />,
        showNot: false,
        locale: 'es',
        addGroupLabel: 'Agregar grupo',
        addRuleLabel: 'Agregar regla',

        customFieldSelectProps: {
            showSearch: true,
        },
    },
    fields: {
        time: {
            label: 'Fecha y hora',
            type: '!struct',
            subfields: {
                timeSlot: {
                    label: 'Franja horaria',
                    type: 'time',
                    operators: ['between']
                },
                day: {
                    label: 'Días',
                    type: 'multiselect',
                    allowCustomValues: false,
                    listValues: {
                        1: 'Lunes',
                        2: 'Martes',
                        3: 'Miércoles',
                        4: 'Jueves',
                        5: 'Viernes',
                        6: 'Sábado',
                        7: 'Domingo'
                    },
                    excludeOperators: ['multiselect_not_equals']
                    //operators: ['equal']
                },
                time: {
                    label: 'Horario',
                    type: 'time',
                    operators: ['equal']
                }
            }
        },
        deviceState: {
            label: 'Dispositivo',
            type: '!struct',
            subfields: {},
        }
    }
};

conditionsConfig.conjunctions.AND.label = 'Y'
conditionsConfig.conjunctions.OR.label = 'O'

conditionsConfig.operators.between.label = 'Entre'
conditionsConfig.operators.between.textSeparators = [null, 'y']
conditionsConfig.operators.equal.label = 'Igual a'
conditionsConfig.operators.multiselect_equals.label = "="

conditionsConfig.widgets.time.valueLabels = ["desde", "hasta"]
conditionsConfig.widgets.multiselect.valuePlaceholder = ['seleccione']


let actionsConfig = {
    ...BasicConfig,
    settings: {
        showNot: false,
        locale: 'es',
        addGroupLabel: 'Agregar grupo',
        addRuleLabel: 'Agregar acción',

        customFieldSelectProps: {
            showSearch: true,
        },
    },
    fields: {
        deviceState: {
            label: 'Dispositivo',
            type: '!struct',
            subfields: {},
        },
        scenarios: {
            label: 'Escenarios',
            type: '!struct',
            subfields: {},
        }
    }
};

actionsConfig.conjunctions.AND.label = 'Y'
actionsConfig.conjunctions.OR.label = 'O'

//actionsConfig.operators.equal.label = 'Igual a'

// Borrar OR a actionsConfig
// El problema es que tanto actionsConfig como conditions config tienen una REFERENCIA a BasicConfig
// Entonces si se cambia uno, se cambia el otro
// JSON.parse(JSON.stringify) hace una copia del objeto entero (no mantiene las referencias)
let actionsConfig2 = JSON.parse(JSON.stringify(actionsConfig))
delete actionsConfig2.conjunctions.OR;
delete actionsConfig.conjunctions.OR;
actionsConfig = actionsConfig2 
actionsConfig.widgets.text = BasicConfig.widgets.text
actionsConfig.widgets.number = BasicConfig.widgets.number
actionsConfig.settings.renderField = (props) => <FieldCascader {...props} />
actionsConfig.operators = {
    turn_ps1: {
        label: 'PRESET 1',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_ps2: {
        label: 'PRESET 2',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_ps3: {
        label: 'PRESET 3',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_ps4: {
        label: 'PRESET 4',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_open: {
        label: 'OPEN',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_close: {
        label: 'CLOSE',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },turn_stop: {
        label: 'STOP',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_toggle: {
        label: 'TOGGLE',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_toggle_multi_device: {
        label: 'TOGGLE',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_on: {
        label: 'PRENDER',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_on_multi_device: {
        label: 'PRENDER',
        // reversedOp: 'turn_off',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_off: {
        label: 'APAGAR',
        // reversedOp: 'not_equal',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    turn_off_multi_device: {
        label: 'APAGAR',
        // reversedOp: 'not_equal',
        labelForFormat: '==',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    pulse_on: {
        label: "PRENDER durante",
        labelForFormat: '==',
        cardinality: 1,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    pulse_on_multi_device: {
        label: "PRENDER durante",
        labelForFormat: '==',
        cardinality: 1,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    pulse_off: {
        label: "Apagar durante",
        labelForFormat: '==',
        cardinality: 1,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    pulse_off_multi_device: {
        label: "Apagar durante",
        labelForFormat: '==',
        cardinality: 1,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    },
    run: {
        label: 'Ejecutar',
        // reversedOp: 'turn_off',
        labelForFormat: '*',
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
    }
}
// console.log(actionsConfig)
// actionsConfig.types.text.widgets.field.operators = ['pulse_on']
// actionsConfig.types.text.widgets.text.operators = ['pulse_on']

actionsConfig.types.number.widgets.number.operators = ['pulse_on', 'pulse_off', "pulse_off_multi_device", "pulse_on_multi_device"]

// console.log(actionsConfig.operators)


export {conditionsConfig, actionsConfig}