import React, { Component } from 'react'
import iro from "@jaames/iro";
import { Row, Col, Button, Modal } from "react-bootstrap";
import IroColorPicker from 'Utils/IroColorPicker';
import "./LedStripPlano.css"
export default class LedStripPlano extends Component {
    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
            selected: "",
            colorActive: "1",
            color1: "rgb(255, 255, 255)",
            color2: "rgb(254, 252, 255)",
            color3: "rgb(254, 252, 255)",
            loading: false
        };
    }

    handleShow() {
        if (!this.props.dragable) {
            this.setState({ show: true });
            
        }
    }
    handleClose() {
        this.setState({ show: false });
    }

    handlePostSensor(id, action, selected) {
        this.setState({ loading: true });
        this.props.postSensor(id, action).then(
            (response) => {
                this.setState({ loading: false });
            }
            )
        this.setState({ selected: selected })
    }

    onColorChange = color => {
        if (this.state.colorActive === "1") {
            this.setState({color1 : color.rgbString});
            this.props.postSensor(this.props.sensor._id, "CHANGE_COLOR", `[[${Object.values(color.rgb)}],[],[]]`  )
        }
        if (this.state.colorActive === "2") {
            this.props.postSensor(this.props.sensor._id, "CHANGE_COLOR", `[[],[${Object.values(color.rgb)}],[]]`  )
            this.setState({color2 : color.rgbString});
        }
        if (this.state.colorActive === "3") {
            this.props.postSensor(this.props.sensor._id, "CHANGE_COLOR", `[[],[],[${Object.values(color.rgb)}]]`  )
            this.setState({color3 : color.rgbString});
        }
        // console.log(color.hexString);
    };

    render() {
        return (
            <>
               <span onClick={() => this.handleShow()} >
                       <span>LED </span>
                    </span>
                <Modal show={this.state.show} onHide={this.handleClose}>
                        {this.state.loading?<div className="modal-loading"><i className="fas fa-spinner fa-spin"></i></div>:null}
                    <Modal.Body>
                        {this.props.sensor._status === 1 && this.props.sensor._status !== null ?
                            this.props.sensor._state !== null && this.props.sensor._state !== undefined ?

                                <>
                                    <div className="text-right">
                                        <div><i style={{ fontWeight: "bold", fontSize: "4.5rem"}} onClick={() => this.handlePostSensor(this.props.sensor._id, this.props.sensor._state !== 0 ? "TURN_OFF" : "TURN_ON")} className={`cursorPointer fas fa-power-off text-${this.props.sensor._state !== 0 ? "success" : "danger"}`}></i></div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <IroColorPicker
                                            width={200}
                                            borderWidth="1"
                                            borderColor="#fff"
                                            layout={[{ component: iro.ui.Wheel }]}
                                            // color={this.state.color}
                                            onColorChange={this.onColorChange}
                                        />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <span onClick={(e)=> this.setState({colorActive: "1"})} style={{backgroundColor: this.state.color1}} className={`btnColor ${this.state.colorActive==="1"? "activeColor": ""}`}>C1</span>
                                        <span onClick={(e)=> this.setState({colorActive: "2"})} style={{backgroundColor: this.state.color2}} className={`btnColor ${this.state.colorActive==="2"? "activeColor": ""}`}>C2</span>
                                        <span onClick={(e)=> this.setState({colorActive: "3"})} style={{backgroundColor: this.state.color3}} className={`btnColor ${this.state.colorActive==="3"? "activeColor": ""}`}>C3</span>
                                    </div>
                                </>
                                :
                                <div>error al traer informacion</div>
                            :
                            <div>DESCONECTADO</div>
                        }
                        <div className="d-flex p-2 btnBox">
                            <button id="ps1" className={this.state.selected == "ps1" ? "btnLed selected" : "btnLed"} type="button" onClick={(e) => this.handlePostSensor(this.props.sensor._id, "TURN_PS1", e.target.id)} >PRESET 1</button>
                            <button id="ps2" className={this.state.selected == "ps2" ? "btnLed selected" : "btnLed"} type="button" onClick={(e) => this.handlePostSensor(this.props.sensor._id, "TURN_PS2", e.target.id)}>PRESET 2</button>
                            <button id="ps3" className={this.state.selected == "ps3" ? "btnLed selected" : "btnLed"} type="button" onClick={(e) => this.handlePostSensor(this.props.sensor._id, "TURN_PS3", e.target.id)}>PRESET 3</button>
                            <button id="ps4" className={this.state.selected == "ps4" ? "btnLed selected" : "btnLed"} type="button" onClick={(e) => this.handlePostSensor(this.props.sensor._id, "TURN_PS4", e.target.id)}>PRESET 4</button>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-xs btn-light btn btn-default" onClick={this.handleClose}>
                            SALIR
                        </Button>
                        {/* <Button
                            className="btn-fill btn-success"
                            onClick={() => console.log("clickkkkk")
                            }
                        >
                            GUARDAR
                        </Button> */}
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}
