import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { UbicacionClima, apikeyClima } from "Services/OpenWeather.js";
import WeatherCard from "components/WeatherCard/WeatherCard";
import PowerGaugeCard from "components/GaugeChart/GaugeChart";
import { traerSensores } from "../Services/Services";

const url =
  "https://api.openweathermap.org/data/2.5/weather?q=" +
  UbicacionClima +
  "&appid=" +
  apikeyClima +
  "";
const url2 =
  "https://api.openweathermap.org/data/2.5/forecast?q=" +
  UbicacionClima +
  "&appid=" +
  apikeyClima +
  "";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prendidos: 0,
      apagados: 0,
      fueralinea: 0,
      luzPrendida: 0,
      airePrendida: 0,
      dispensadorPrendido: 0,
      totalkw: 0,
    };
  }

  //// SE RECORREN LOS SENSORES Y SE VERIFICA CUALES ESTAN ENCENDIDOS Y APAGADOS ///
  verEncendidos() {
    var totalkw = 0;
    var prendidos = 0;
    var apagados = 0;
    var fueralinea = 0;
    var sensores = this.state.sensores;
    sensores.map((sensor, h) => {
      if (sensor._status === 0) {
        fueralinea = fueralinea + 1;
      } 
      else if (sensor._status === 1) {
          if (sensor._state === 1) {
            prendidos = prendidos + 1;
            totalkw = totalkw + sensor._power;
          }else{
            apagados = apagados + 1;
          }
      }
    });
    this.setState({ prendidos, apagados, fueralinea, totalkw });
  }

  //// SE CONSULTA EL CLIMA EN TIEMPO REAL ///
  traerClimaTiempoReal(url) {
    fetch(url)
      .then((response) => response.json())
      .then((climaAhora) => this.setState({ climaAhora }));
  }
  //// SE CONSULTA PREDICCION DEL CLIMA  ///
  TraerClimaPrediccion(url) {
    fetch(url)
      .then((response) => response.json())
      .then((prediccionClima) => this.setState({ prediccionClima }));
  }

  async componentDidMount() {
    this.traerClimaTiempoReal(url);
    this.TraerClimaPrediccion(url2);
    await traerSensores().then((sensores) => {
      this.setState({ sensores });
    });

    this.verEncendidos();
  }

  render() {
    // console.log(this.state.totalkw);
    if (!this.state.climaAhora || !this.state.prediccionClima) {
      return null;
    }
    return (
      <div className="content">
        <Grid fluid>
          <div style={{ width: "100%", textAlign: "center" }}></div>
          <Row>
            <Col lg={3} sm={4}>
              <WeatherCard
                climaAhora={this.state.climaAhora}
                prediccionClima={this.state.prediccionClima}
              />
            </Col>
            <Col lg={3} sm={4}>
              <PowerGaugeCard
                max={20000} // en watts
                value={this.state.totalkw?this.state.totalkw:0}
                scale={0.001}
                unity={"KW"}
                prendidos={this.state.prendidos}
                apagados={this.state.apagados}
                fueralinea={this.state.fueralinea}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
