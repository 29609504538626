import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

export class TempCard extends Component {
  render() {
    // console.log(this.props.statsValue +"  / LQ "+this.props.linkQuality+"  / B "+this.props.batteryPercentage);
    return (
      <div className="card card-stats">

        {this.props.editar}
        <div style={{ padding: "4px 0px 0px 4px", position: "absolute", fontSize: "1.1rem" }}>
          {this.props.linkQuality >= 0 || this.props.status >= 0 ? (

            <i
              className={
                this.props.status === 0
                  ? "fas fa-wifi text-muted"
                  : this.props.linkQuality === 0
                    ? "fas fa-wifi text-muted"
                    : this.props.linkQuality < 11
                      ? "fas fa-wifi text-danger"
                      : this.props.linkQuality < 41
                        ? "fas fa-wifi text-warning"
                        : this.props.linkQuality
                          ? "fas fa-wifi text-success"
                          : this.props.status > 0
                            ? "fas fa-wifi text-success"
                            : null}
            ></i>
          ) : null}
          {this.props.batteryPercentage >= 0 ? (
            <div >
              <i
                className={
                  this.props.batteryStatus == 2
                    ? "fas fa-plug text-muted"
                    : this.props.batteryPercentage === 0
                      ? "fas fa-battery-quarter text-muted"
                      : this.props.batteryPercentage < 11
                        ? "fas fa-battery-quarter text-danger"
                        : this.props.batteryPercentage < 51
                          ? "fas fa-battery-half text-warning"
                          : this.props.batteryPercentage < 76
                            ? "fas fa-battery-three-quarters text-success"
                            : this.props.batteryPercentage
                              ? "fas fa-battery-full text-success"
                              : null
                }
              ></i>
              <div className="text-muted"><small>{this.props.batteryPercentage}%</small></div>
            </div>
          ) : null}

        </div>

        {/* {this.props.status &&  this.props.status === 0 ? (
          <i style={{ padding: "4px 0px 0px 4px", position: "absolute", fontSize: "1.1rem" }} className={"fas fa-wifi text-success"}></i>
        ) : <i style={{ padding: "4px 0px 0px 4px", position: "absolute", fontSize: "1.1rem" }} className={"fas fa-wifi text-grey"} ></i>}
        */}
        {/* {this.props.batteryPercentage >= 0 ? (
          <i style={{ padding: "20px 0px 0px 4px", position: "absolute", fontSize: "1.1rem" }}
            className={
              this.props.batteryPercentage === 0
                ? "fas fa-battery-quarter text-muted"
                : this.props.batteryPercentage < 11
                  ? "fas fa-battery-quarter text-danger"
                  : this.props.batteryPercentage < 51
                    ? "fas fa-battery-half text-warning"
                    : this.props.batteryPercentage < 76
                      ? "fas fa-battery-three-quarters text-success"
                      : this.props.batteryPercentage
                        ? "fas fa-battery-full text-success"
                        : null
            }
          ></i>
        ) : null} */}
        <div className="content">
          <Row>
            <Col xs={12}>
              <div className="icon-big text-center icon-warning">
                {this.props.bigIcon}
                <span className="tempCardHeader">

                  {this.props.temp || this.props.temp === 0 ? <span style={{ display: "flex", flexDirection: "column" }}><i className="fas fa-thermometer-half text-success"></i>{" " + this.props.temp.toFixed(1) + "°C"}</span> : null}
                  {this.props.hum ? <span style={{ display: "flex", flexDirection: "column" }}><i className="fab fa-empire text-info"></i>{" " + this.props.hum.toFixed(0) + "%"}</span> : null}
                </span>
              </div>
            </Col>
            <Col xs={12}>
              <div className="numbers">
                <p>{this.props.statsText}</p>
                {this.props.statsValue}
              </div>
            </Col>
          </Row>
          <div className="footer">
            <hr />
            <div className="stats">
              {this.props.statsIcon} {this.props.statsIconText}
            </div>
          </div>
          {this.props.historics ? this.props.historics : null}
        </div>
      </div>
    );
  }
}

export default TempCard;
