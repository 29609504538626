import React, { Component } from 'react'
import "./BtnEscenarios.css"
export default class BtnEscenarios extends Component {
    acortarTexto(texto) {
        if (texto.length > 17) {
            return texto.substr(0, 17) + "..."
        } else {
            return texto
        }
    }

    render() {
        return (

            <div className="cursorPointer itemBtnEscenarios" onClick={() => this.props.ejecutarEscenario(this.props.escenario)}>
                <img alt={this.props.nombre} className="imgBtnEscenarios" src={this.props.imagen} />
                <p>{this.acortarTexto(this.props.nombre)}</p>
            </div>

        )
    }
}
