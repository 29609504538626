import React, { Component } from 'react';
import { Query, Builder, BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder';
import Swal from 'sweetalert2'
import { parseRegla, parseAccion, traerAtributos, traerEscenarios, parseConfig, parseConfigActions, parseConfigActionsScenarios, mandarRegla, traerReglas, eliminarRegla, initTree} from '../Services/Reglas';
import Regla from '../components/Regla';
import 'react-awesome-query-builder/css/antd.less';
import "antd/dist/antd.css";
import 'react-awesome-query-builder/css/styles.scss';
import 'react-awesome-query-builder/css/compact_styles.scss'; //optional, for more compact styles;
import { Grid, Row, Col, Table, Button } from "react-bootstrap";


import Arbol from 'components/ArbolReglas/Arbol';
import {conditionsConfig, actionsConfig} from 'components/ArbolReglas/Configs';
export default class Reglas extends Component {
  constructor(props) {
    super(props)
    console.log(conditionsConfig.conjunctions)
    this.state = {
      conditionsConfig: conditionsConfig,
      actionsConfig: actionsConfig,
      reglas: [],
      arbolCondicionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(initTree), conditionsConfig),
      arbolAccionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(initTree), actionsConfig),
    };
  }

  componentDidMount() {

    Promise.all([traerAtributos(false), traerAtributos(true), traerEscenarios()])
      .then( ([todos, soloModificables, escenarios]) => {
        conditionsConfig.fields.deviceState.subfields = parseConfig(todos)
        actionsConfig.fields.deviceState.subfields = parseConfigActions(soloModificables)
        console.log(todos);
        if (escenarios.length==0) {
          delete actionsConfig.fields.scenarios
        }else {

          actionsConfig.fields.scenarios.subfields = parseConfigActionsScenarios(escenarios)
        }
        this.setState({ conditionsConfig, actionsConfig, dispositivos: todos, escenarios})
      })
    traerReglas()
      .then(reglas => this.setState({reglas}))
  }

  renderBuilder = (props) => (
    <div className="query-builder-container" style={{ padding: '10px' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  )


  conditionChanged = (immutableTree, config) => {
    // if (this.state.reglaSeleccionada !== undefined)
    //   return
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    this.setState({ conditionTree: immutableTree, conditionsConfig: config });
  }

  actionChanged = (immutableTree, config) => {
    // if (this.state.reglaSeleccionada !== undefined)
    //   return
    this.setState({ actionTree: immutableTree, actionsConfig: config });
  }

  guardarRegla = (e) => {
    let conditionTree = QbUtils.getTree(this.state.conditionTree, this.state.conditionsConfig)
    let actionTree = QbUtils.getTree(this.state.actionTree, this.state.actionsConfig)
    let regla = parseRegla(conditionTree, this.state.dispositivos)
    regla.events = parseAccion(actionTree, this.state.dispositivos, this.state.escenarios)
    regla.name = this.state.nombreRegla
    regla.rawRule = conditionTree
    regla.rawActions = actionTree
    console.log(conditionTree);
    mandarRegla(regla)
      .then(reglaNueva => {
        reglaNueva.visualAction = reglaNueva._visualAction;
        reglaNueva.visualCondition = reglaNueva._visualCondition;
        reglaNueva.rawRule = reglaNueva._rawRule;
        reglaNueva.rawAction = reglaNueva._rawAction;
        reglaNueva.name = reglaNueva._name;
        reglaNueva.events = reglaNueva._conditions;
        let reglas = this.state.reglas;
        reglas.push(reglaNueva);
        this.cancelarEditar();
      })
  }
  
  actualizarNombreRegla = e => {
    let nombreRegla = e.target.value;
    this.setState({nombreRegla})
  }
  
  mostrarRegla = regla => {
    this.setState({
      nombreRegla: regla.name,
      reglaSeleccionada: regla,
      arbolCondicionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(regla.rawRule), this.state.conditionsConfig),
      arbolAccionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(regla.rawAction), this.state.actionsConfig),
    })
  }

  cancelarEditar = () => {
    this.setState({
      nombreRegla: "",
      reglaSeleccionada: null,
      arbolCondicionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(initTree), conditionsConfig),
      arbolAccionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(initTree), actionsConfig),
    });
  }
  aplicarEdicion = () => {
    eliminarRegla(this.state.reglaSeleccionada)
    .then(reglas => {
        this.setState({
          reglas: reglas
        }, () => {
          this.guardarRegla()
        })
      })
    }
    
    eliminarRegla = (regla) => {
      eliminarRegla(regla)
      .then(reglas => this.setState({reglas}))
    }

    showButtons = () => {
      if (!this.state.reglaSeleccionada)
      return (
        <button onClick={this.guardarRegla} type="button" className="btn-fill btn btn-success">GUARDAR REGLA</button>
        )
        else 
      return (
        <>
          <button onClick={this.aplicarEdicion} type="button" className="btn-fill btn btn-success btnAplicar"><i class="fas fa-check"></i></button>
          <button onClick={this.cancelarEditar} type="button" className="btn-fill btn btn-danger btnCancelar"><i class="fas fa-ban"></i></button>
        </>
      )
    }
    
    
  render() {

    return (
      <div className="text-center">
        <Arbol id="condicion" tree={this.state.arbolCondicionesReglaSeleccionada} title="CONDICIÓN" config={this.state.conditionsConfig} onChange={this.conditionChanged}/>
        <Arbol id="accion" tree={this.state.arbolAccionesReglaSeleccionada} title="ACCIÓN" config={this.state.actionsConfig} onChange={this.actionChanged}/>
        
        <input onChange={this.actualizarNombreRegla} disabled={this.state.reglaSeleccionada} value={this.state.nombreRegla} placeholder="Nombre de la regla" type="text" className="form-control" style={{width: "auto", display: "inline-block", verticalAlign: "middle"}}></input>

        {this.showButtons()}
        
        
        <hr></hr>
        <div className="text-center h3">REGLAS ACTIVAS <i className="far fa-check-circle"></i>
          <Col md={6} className="text-left tablaReglas col-md-6 marginauto float-none">
            <Table hover>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Acción</th>
                </tr>
                <tr>
                </tr>
              </thead>
              <tbody>
                {this.state.reglas.map((regla, indice) => <Regla key={indice} regla={regla} mostrarRegla={this.mostrarRegla} SetDelete={this.eliminarRegla}></Regla>)}
              </tbody>
            </Table>
          </Col>
        </div>
      </div>

    )
  }
}