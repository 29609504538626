import * as io from 'socket.io-client';
import config from "../config"
import React, { Component } from 'react';
import DispositivoTipo from "components/DispositivoTipo/DispositivoTipo"
import { filtrarSelect } from "../Utils/Utils";
import { traerSensores, postSensor, traerUbicacionEdificios } from "../Services/Services"
import Mapa from 'components/Mapa/Mapa';

export default class Dispositivos extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.socket = null;
        this.state = {
            sensoresMarkers: [],
            markers:[],
            sensores: [],
            filtro: "",
            edificio: "",
            piso: "",
            tipo: "",
            zona: "",
        };
    }

    crearSocket() {
        if (this.socket) {
            this.socket.disconnect();
          }
        this.socket = io(config.socketUrl);
        this.socket.on('newData', data => {
            let sensores = JSON.parse(data)
            let newmarkers=[]
            let sensoresdata =sensores
            if (this.state.edificio !== "") {
              sensoresdata = filtrarSelect(this.state.edificio, this.state.piso, sensores, "path", "edificio")
            }
            if (this.state.piso !== "") {
                sensoresdata = filtrarSelect(this.state.edificio, this.state.piso, sensores, "path", "piso")
            }
            if (this.state.tipo !== "") {
                sensoresdata = filtrarSelect(this.state.edificio, this.state.tipo, sensores, "_device_type")
            }
            if (this.state.zona !== "") {
                sensoresdata = filtrarSelect(this.state.edificio, this.state.zona, sensores, "_zone")
            }

            for (let index = 0; index < this.state.markers.length; index++) {
                const filteredSensors = sensores.filter((sensor) => sensor.path.split("/")[0] === this.state.markers[index].building);
                newmarkers.push({...this.state.markers[index], sensors: filteredSensors})
            
            }


            this.setState({ sensores: sensoresdata, sensoresMarkers: newmarkers});
        //     if (this.state.edificio !== "") {
        //         sensores = filtrarSelect(this.state.edificio, this.state.piso, sensores, "path", "edificio")
        //     }
        //     if (this.state.piso !== "") {
        //         sensores = filtrarSelect(this.state.edificio, this.state.piso, sensores, "path", "piso")
        //     }
        //     if (this.state.tipo !== "") {
        //         sensores = filtrarSelect(this.state.edificio, this.state.tipo, sensores, "_device_type")
        //     }
        //     if (this.state.zona !== "") {
        //         sensores = filtrarSelect(this.state.edificio, this.state.zona, sensores, "_zone")
        //     }
        //     this.setState({ sensores})
        })
        const service = localStorage.getItem("service")
        const token = localStorage.getItem("token")
        this.socket.emit('discover', { service, token })
    }

    componentDidMount() {
        this._isMounted = true;
        this.actualizarSensores()
        this.crearSocket();
        // traerUbicacionEdificios().then(
        //     resp=>{this.setState({markers: resp})}
            
        //     )

    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.socket) {
            this.socket.disconnect();
          }
      }
      
      ////// ACTUALIZO LOS SENSORES DISPONIBLES //////
    actualizarSensores() {
        Promise.all([traerSensores(), traerUbicacionEdificios()])
        .then(([sensores, markers]) => {
            if (this._isMounted) {
              let newmarkers=[]
            let sensoresdata =sensores
            if (this.state.edificio !== "") {
              sensoresdata = filtrarSelect(this.state.edificio, this.state.piso, sensores, "path", "edificio")
            }
            if (this.state.piso !== "") {
                sensoresdata = filtrarSelect(this.state.edificio, this.state.piso, sensores, "path", "piso")
            }
            if (this.state.tipo !== "") {
                sensoresdata = filtrarSelect(this.state.edificio, this.state.tipo, sensores, "_device_type")
            }
            if (this.state.zona !== "") {
                sensoresdata = filtrarSelect(this.state.edificio, this.state.zona, sensores, "_zone")
            }

            for (let index = 0; index < markers.length; index++) {
                const filteredSensors = sensores.filter((sensor) => sensor.path.split("/")[0] === markers[index].building);
                newmarkers.push({...markers[index], sensors: filteredSensors})
            
            }


            this.setState({ sensores: sensoresdata, sensoresMarkers: newmarkers, markers});
          }
        })
        .catch(error => {
          // Manejo de errores
        });
    }

    handleSelect(dato, valor) {
        this.setState({ [dato]: valor })
        this.actualizarSensores()

    }
    ///// ENVIA LOS SENSORES DISPONIBLES O FILTRADOS A DISPOSITIVOTIPO /////
    amarDivSensores() {
        if (this.state.sensores.length>0) {
            return this.state.sensores.map((sensor, h) => {
                return (
                    <DispositivoTipo
                        key={h}
                        sensor={sensor}
                        postSensor={e => postSensor(e)}
                        actualizar={() => this.actualizarSensores()}
                    />
                )
            })
        }
    }

    /**
    * RECOGE LOS DISTINTOS TIPOS DE VALORES QUE TIENE UN ATRIBUTOS Y MUESTRA UN SELECT.
    * @param {string} atributo -atributo del sensor que queremos obtener.
    * @param {string} dato - nombre "prolijo" del atributo.
    * @return {string} distintos valores que tiene ese atributo en todos los sensores.
    */
    selectDinamic(atributo, dato) {
        if (this.state.sensores) {
            let atributoValor = ""
            if (atributo === "path") {
                atributoValor = this.state.sensores.map((sensor) => {
                    return sensor[atributo].split('/')[dato === "piso" ? 1 : 0]
                })
            } else {
                atributoValor = this.state.sensores.map(sensor => sensor[atributo])

            }
            let sinRepetidos = atributoValor.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
            let opciones = sinRepetidos.map((tipo, h) => {
                return <option key={tipo + h} value={tipo}>{tipo ? tipo.toUpperCase() : null}</option>
            })
            return <select className="cursorPointer SelectTipos" value={this.state[dato]} onChange={e => this.handleSelect([dato], e.target.value)}>
                <option value=""> {dato.toUpperCase() + "S"}</option>
                {opciones}
            </select>
        }
    }

    limpiarFiltros() {
        this.setState({ piso: "", edificio: "", zona: "", tipo: "", })
        this.actualizarSensores()
    }

    render() {
        console.log(this.state.sensores);
        const sensoresDiv = this.amarDivSensores()
        const edificioDinamic = this.selectDinamic("path", "edificio")
        const pisosDinamic = this.selectDinamic("path", "piso")
        const zonasDinamic = this.selectDinamic("_zone", "zona")
        const tiposDinamic = this.selectDinamic("_device_type", "tipo")
        return (
            <div className="content">
                {this.state.sensoresMarkers.length>0&&
                <Mapa
                    containerElement={<div style={{ height: '300px' }} />}
                    mapElement={<div style={{ height: '100%' }} />}
                    markers={this.state.sensoresMarkers}
                />}
                <div className="BoxSelect">
                    {edificioDinamic}
                    {pisosDinamic}
                    {zonasDinamic}
                    {tiposDinamic}
                    {/* <button type="button" className="" onClick={()=>this.limpiarFiltros()}>borrar</button> */}
                    <button type="button" className="btnClearFilter" onClick={() => this.limpiarFiltros()}><i className="fas fa-rotate-left"></i></button>

                </div>
                <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>

                    {sensoresDiv}
                </div>
            </div>
        )
    }
}
