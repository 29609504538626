import React, {Component} from 'react';
import { Query, Builder, BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder';

export default class Arbol extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tree: props.initTree,
            // config: props.config,
        }
    }

    renderBuilder = (props) => (
        <div className="query-builder-container" style={{ padding: '10px' }}>
          <div className="query-builder qb-lite">
            <Builder {...props} />
          </div>
        </div>
      )

    render() {
        return (

            <div className={"text-center h3 " + this.props.id}>{this.props.title} <i className="fas fa-angle-double-down"></i>
                <Query
                    {...this.props.config}
                    value={this.props.tree}
                    onChange={this.props.onChange}
                    renderBuilder={this.renderBuilder}
                />
            </div>
        )
    }


}