import config from '../config';

const parseAccion = (arbol, dispositivos) => {
    let eventos = parseNodoAccion(arbol, dispositivos);
    return eventos;
}

const parseNodoAccion = (nodo, dispositivos) => {
    let hijos = []
    if (nodo.type === "group"){
        for (let idHijo in nodo.children1) {
            let hijo = nodo.children1[idHijo]
            if (hijo.type === "group" || hijo.type === "rule") {
                let acciones = parseNodoAccion(hijo, dispositivos)
                hijos.push(...acciones)
            }
        } 
    } else if (nodo.type === "rule"){
        let evento = {}
        let nombreDispositivo = nodo.properties.field
        
        let ids = dispositivos.filter(dispositivo => dispositivo.name === nombreDispositivo);
        if (ids.length === 0)
            throw new Error("Dispositivo no encontrado")
        else if (ids.length > 1)
            throw new Error("Nombre ambiguo")
        
        let id = ids[0].id;

        switch (nodo.properties.operator){ //turn_on, turn_off, pulse_on, pulse_off
            case "turn_toggle":
                evento.type = "ToggleDevice"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_on":
                evento.type = "TurnDeviceOn"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_off":
                evento.type = "TurnDeviceOff"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "pulse_on":
                evento.type = "PulseDevice"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                    pulseType: "ON",
                    interval: nodo.properties.value[0]*1000
                }
                break;
            case "pulse_off":
                evento.type = "PulseDevice"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                    pulseType: "OFF",
                    interval: nodo.properties.value[0]*1000
                }
                break;
        }
        hijos.push(evento)
    }
    return hijos
}


/**
 * @author Juan Andrés Mezzera
 * @method parseRegla
 * @description Crea, a partir de una regla generada por el paquete utilizado para 
 *              diagramar reglas, una regla según el formato aceptado por el BE.
 * @param {Object} cruda regla a ser parseada
 * @returns regla parseada
 */
const parseRegla = (cruda, dispositivos) => {
    let parseada = {}
    parseada.conditions = manejarNodo(cruda, dispositivos);

    return parseada;
}

/**
 * @author Juan Andrés Mezzera
 * @method manejarNodo
 * @description Método auxiliar utilizado para -recursivamente- manejar cada uno
 *              de los nodos del árbol.
 * @param {Object} nodo Nodo a ser procesado
 * @returns representación del nodo en el formato deseado
 */
const manejarNodo = (nodo, dispositivos) => {
    //TODO: Manejar opción NOT
    let condicion = {}
    if (nodo.type === "group") {
        //Recorrer todos los hijos
        let hijos = [];
        for (let idHijo in nodo.children1) {
            let hijo = nodo.children1[idHijo]
            if (hijo.type === "group" || hijo.type === "rule") {
                hijos.push(manejarNodo(hijo, dispositivos))
            }
        }
        if (!nodo.properties) {
            condicion = { "any": hijos }
        } else if (nodo.properties.conjunction === "AND") {
            condicion = { "all": hijos }
        } else if (nodo.properties.conjunction === "OR") {
            condicion = { "any": hijos }
        } else {
            throw new Error("Error al armar la regla")
        }
    } else if (nodo.type === "rule") {
        let campo = nodo.properties.field.split('.')
        let fact = campo[0]
        condicion.fact = fact
        condicion.operator = operadores[nodo.properties.operator]

        switch (fact) {
            case "deviceState":
                let ids = dispositivos.filter(dispositivo => dispositivo.name === campo[1]);
                if (ids.length === 0)
                    throw new Error("Dispositivo no encontrado")
                else if (ids.length > 1)
                    throw new Error("Nombre ambiguo")

                let id = ids[0].id;
            
                condicion.params = {
                    service: localStorage.getItem("service"),
                    id,
                }
                // condicion.fact = 'device'
                condicion.path = '$._state'
                condicion.operator = 'equal'
                condicion.value = nodo.properties.value[0] ? 1 : 0

                // condicion.path = "$._" + campo[2]
                // condicion.value = nodo.properties.value[0] ? 1 : 0
                break;
            case "time":
                switch (campo[1]){
                    case "day":
                        condicion.fact = 'day'
                        condicion.value = nodo.properties.value[0];
                        condicion.operator = 'in'
                        break;
                    case "time":
                        const [hours, minutes, _] = nodo.properties.value[0].split(':');
                        condicion.params = {hours, minutes};
                        condicion.value = true;
                        break;
                    default:
                        throw new Error("No implementado")
                }
                break;             

            default:
                throw new Error("Error al armar la regla: no se conoce el fact " + fact)
        }
    }
    return condicion
}

//Mapa para convertir operadores entre el módulo gráfico y los aceptados por 
//el BE
const operadores = { equal: "equal", multiselect_equals: "in" }


const traerAtributos = async (onlyChangeable) => {
    let urlEnding = onlyChangeable ? 'onlyChangeable' : 'all'
    const resp = await fetch(`${config.baseUrl}/rules/options/${urlEnding}`,
        {
            headers: {
                service: localStorage.getItem("service"),
                token: localStorage.getItem("token")
            }
        });
    return await resp.json();
}

const parseConfig = (atributos) => {
    let opciones = {}
    for (let atributo of atributos) {
        let attrs = {}
        for (let attr in atributo.attrs) {
            let estructura = crearAtributo(atributo.attrs[attr], attr)
            if (estructura)
                attrs[attr] = estructura
        }
        opciones[atributo.name] = {
            label: atributo.name,
            type: '!struct',
            subfields: attrs,
        }
    }
    return opciones
}

const crearAtributo = (atributo, nombre) => {
    let estructura;
    switch (nombre) {
        case "power":
            estructura = {
                label: "potencia",
                type: "number",
                fieldSettings: {
                    min: 0,
                },
                operators: ['between', 'less', 'greater'],
                valueSources: ['value'],
                preferWidgets: ['number'],
            }
            break;
        case "state":
            estructura = {
                name: 'estado',
                label: 'Estado',
                type: 'boolean',
                operators: ['equal'],
                fieldSettings: {
                    labelYes: '',
                    labelNo: ''
                }
            }
            break;
        case "temperature":
            estructura = {
                label: 'Temperatura',
                type: 'number',
                valueSources: 'temperature',
                fieldSettings: {
                    min: 0,
                    max: 50
                },
                defautlValue: 20
            }
            break;
    }
    return estructura
}

const parseConfigActions = (atributos) => {
    let campo = {}
    for (let atributo of atributos){
        // console.log(atributo)
        campo[atributo.name] = {
            label: atributo.name,
            type: 'number',
            operators: ['turn_on', 'turn_off', 'pulse_on', 'pulse_off','turn_toggle'],
            //operators: ['turn_on', 'turn_off']
        }
    }
    return campo
}

const mandarEscenario = async escenario => {
    const resp = await fetch(`${config.baseUrl}/rules/scenarios`, {
        headers: {
            'content-type': 'application/json',
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        },
        method: "POST",
        body: JSON.stringify(escenario)
    });
    return resp;
    
   
}
const ejecutarEscenario = async escenario => {
    const resp = await fetch(`${config.baseUrl}/rules/scenarios/${escenario._id}`, {
        headers: {
            'content-type': 'application/json',
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        },
        method: "POST",
        body: JSON.stringify(escenario)
    });
    return resp.status;
    
   
}

const eliminarEscenario = async escenario => {

    const resp = await fetch(`${config.baseUrl}/rules/scenarios/${escenario._id}`, {
        headers: {
            //'content-type': 'application/json',
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        },
        method: "DELETE"
    });
    return resp.status;
}

const traerEscenarios = async () => {
    const resp = await fetch(`${config.baseUrl}/rules/scenarios`,
        {
            headers: {
                service: localStorage.getItem("service"),
                token: localStorage.getItem("token")
            }
        });
    return await resp.json();
}

const initTree = {
    "type": "group",
    "id": "9a99988a-0123-4456-b89a-b1607f326fd8",
    "children1": {
        "a98ab9b9-cdef-4012-b456-71607f326fd9": {
            "type": "rule",
            "properties": {
                "field": null,
                "operator": null,
                "value": [],
                "valueSrc": []

            },
        }
    },
    "properties": {
        "conjunction": "AND",
        "not": false
    }
}
export { parseRegla, traerAtributos, parseConfig, parseConfigActions, parseAccion, initTree, mandarEscenario, traerEscenarios, eliminarEscenario,ejecutarEscenario }