import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
// import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import MobileNav from "components/MobileNav/MobileNav"
import { style } from "variables/Variables.jsx";

import routes from "routes.js";

import image from "assets/img/sidebar-7.jpg";
import config from "../config";

class Admin extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false
    this.state = {
      _notificationSystem: null,
      image: image,
      color: "azure",
      hasImage: true,
      fixedClasses: "dropdown",
      colorLine: "color-line-azure",
    };
  }
  handleNotificationClick = position => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component
                {...props}
                handleClick={this.handleNotificationClick}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  handleImageClick = image => {
    if (this._isMounted) {
    this.setState({ image: image });
    localStorage.setItem('image', image);
    }
  };
  handleColorClick = color => {
    if (this._isMounted) {
    this.setState({ color: color, colorLine: "color-line-" + color });
    localStorage.setItem('color', color);
    localStorage.setItem('colorLine', "color-line-" + color);
    }
  };
  handleHasImage = hasImage => {
    if (this._isMounted) {
    this.setState({ hasImage: hasImage });
    }
  };
  handleFixedClick = () => {
    if (this._isMounted) {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  }
  };
  componentDidMount() {
    this._isMounted = true;
    var colorLS = localStorage.getItem('color');
    var colorLineLS = localStorage.getItem('colorLine');
    var imageLS = localStorage.getItem('image');
    if (colorLS !== null && colorLineLS !== null) {
      this.setState({ color: colorLS, colorLine: colorLineLS })
    }
    if (imageLS !== null) {
      this.setState({ image: imageLS })
    }
    // this.setState({ _notificationSystem: this.refs.notificationSystem });
    // var _notificationSystem = this.refs.notificationSystem;
    // var color = Math.floor(Math.random() * 4 + 1);
    // var level;
    // switch (color) {
    //   case 1:
    //     level = "success";
    //     break;
    //   case 2:
    //     level = "warning";
    //     break;
    //   case 3:
    //     level = "error";
    //     break;
    //   case 4:
    //     level = "info";
    //     break;
    //   default:
    //     break;
    // }
    // _notificationSystem.addNotification({
    //   title: <span data-notify="icon" className="pe-7s-gift" />,
    //   message: (
    //     <div>
    //       Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
    //       every web developer.
    //     </div>
    //   ),
    //   level: level,
    //   position: "tr",
    //   autoDismiss: 15
    // });
  }
  componentWillUnmount() {
    this._isMounted = false;
    // Cancela tus tareas asincrónicas o suscripciones aquí
    // por ejemplo:
    // clearInterval(this.timer);
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <div className={this.state.colorLine}></div>
        <Sidebar {...this.props} routes={routes} image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
          setting={<FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            handleHasImage={this.handleHasImage}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            mini={this.state["mini"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          />} />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          {/* <Footer /> */}

        </div>
        <MobileNav color={this.state.color} colorLine={this.state.colorLine}></MobileNav>
      </div>
    );
  }
}

export default Admin;
