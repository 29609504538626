///// MUESTRA SOLO LOS DISPOSITIVOS SEGUN EL PISO SELECCIONADO EN EL SELECT /////
function filtrarSelect(edificio, piso, sensores, atributo, detalle) {
  if (atributo ==="path") {
    if (detalle === "edificio") {
      let result = []
      result = sensores.filter(sensores => sensores[atributo].split("/")[0].toLowerCase() === edificio.toLowerCase());
      return result
  } else if (detalle === "piso") {
    let result = []
    result = sensores.filter(sensores => sensores[atributo].split("/")[1].toLowerCase() === piso.toLowerCase());
    return result
  } else {
    let result = []
    result = sensores.filter(sensores => sensores[atributo].toLowerCase() === edificio + "/" + piso.toLowerCase());
    return result
  }
}else{
  let result = []
  result = sensores.filter(sensores => sensores[atributo].toLowerCase() === piso.toLowerCase());
  return result
}
}

export { filtrarSelect }