import React, { Component } from "react";
import { Tooltip, OverlayTrigger, Button, Modal } from "react-bootstrap";
import "./DispositivoTipo.css";
import { SimpleCard } from "components/SimpleCard/SimpleCard.jsx";
import { PowCard } from "components/PowCard/PowCard.jsx";
import { RemoteControl } from "components/RemoteControl/RemoteControl.jsx";
import { patchSensor, postSensor } from "../../Services/Services";
import TempCard from "components/TempCard/TempCard";
import AirQCard from "components/AirQCard/AirQCard";
import CortinaCard from "components/CortinaCard/CortinaCard";
import Toggle from "react-toggle";
import LedStrip from "components/LedStrip/LedStrip";
import HistoricsGraph from "components/HistoricsGraph/HistoricsGraph";
import WeatherStation from "components/WeatherStation/WeatherStation";
export default class DispositivoTipo extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShowEdit.bind(this);
    this.handleShow = this.handleShowHistorics.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleReverse = this.handleReverse.bind(this);

    this.state = {
      show: false,
      curtainReverse: false,
      showHistoricPopup: false,
    };
  }



  async editarSensor(id, nombre, zona, power, reverse, state) {
    if (nombre !== null && nombre !== "" && nombre !== undefined) {
      await patchSensor("name", id, nombre);
    }
    if (zona !== null && zona !== "" && zona !== undefined) {
      await patchSensor("zone", id, zona);
    }
    if (power !== null && power !== "" && power !== undefined) {
      await patchSensor("power", id, power);
    }
    if (reverse !== null && reverse !== "" && reverse !== undefined) {
      await patchSensor("reverse", id, reverse === true ? 1 : 0);
    }
    if (state !== null && state !== "" && state !== undefined) {
      await patchSensor("state", id, state);
    }
    this.props.actualizar();
    this.handleClose();
  }

  handleReverse(dato) {
    this.setState({ curtainReverse: dato })
  }

  handleClose() {
    this.setState({ show: false });
    this.setState({ showHistoricPopup: false });
  }
  handleShowEdit() {
    this.setState({ show: true });
  }

  handleShowHistorics() {
    this.setState({ showHistoricPopup: true })
  }


  armarTarjetaSensor() {
    var h = this.props.h;
    var id = this.props.sensor._id;
    var status = this.props.sensor._status;
    var nombre = this.props.sensor._name;
    var deviceType = this.props.sensor._device_type;
    var tipo = this.props.sensor._type;
    var estado = this.props.sensor._state;
    var estados = this.props.sensor._states;
    var zona = this.props.sensor._zone;
    var piso = this.props.sensor.path;
    // var total = this.props.sensor._total
    // var yesterday = this.props.sensor._yesterday
    var today = this.props.sensor._today;
    var power = this.props.sensor._power;
    var powers = this.props.sensor._powers;
    // var apparentpower = this.props.sensor._apparentPower
    // var reactivepower = this.props.sensor._reactivePower
    var factor = this.props.sensor._powerFactor || this.props.sensor._factor;
    var factors = this.props.sensor._powerFactors;
    var voltage = this.props.sensor._voltage;
    var current = this.props.sensor._current;
    var currents = this.props.sensor._currents;
    var temperatura = this.props.sensor._temperature;
    var humedad = this.props.sensor._humidity;
    var batteryPercentage = this.props.sensor._batteryPercentage;
    var batteryVoltage = this.props.sensor._batteryVoltage;
    var linkQuality = this.props.sensor._linkQuality;
    var CO2 = this.props.sensor._CO2;
    var HCHO = this.props.sensor._HCHO;
    var VOC = this.props.sensor._VOC;
    var position = this.props.sensor._position;
    var reverse = this.props.sensor._reverse;
    var dewpoint = this.props.sensor._dewpoint;
    var light = this.props.sensor._light;
    var pressure = this.props.sensor._pressure;
    var rain = this.props.sensor._rain;
    var relative_humidity = this.props.sensor._relative_humidity;
    var wind_direction = this.props.sensor._wind_direction;
    var wind_speed = this.props.sensor._wind_speed;

    var modalConPow = (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Body>
          <div className="form-group">
            <label forhtml="form-nombre">Nombre</label>
            <input
              id="nombre"
              name="form-nombre"
              placeholder={nombre}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label forhtml="form-power">Consumo Estimado</label>
            <input
              id="consumo"
              type="text"
              name="form-power"
              placeholder={power}
              className=" form-control"
            />
          </div>
          <div className="form-group">
            <label forhtml="form-zona">Zona</label>
            <input
              id="zona"
              type="text"
              name="form-zona"
              placeholder={zona}
              className=" form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-fill btn-danger" onClick={this.handleClose}>
            CANCELAR
          </Button>
          <Button
            className="btn-fill btn-success"
            onClick={() =>
              this.editarSensor(
                id,
                document.getElementById("nombre").value,
                document.getElementById("zona").value,
                document.getElementById("consumo").value
              )
            }
          >
            GUARDAR
          </Button>
        </Modal.Footer>
      </Modal>
    );
    var modalSinPow = (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Body>
          <div className="form-group">
            <label forhtml="form-nombre">Nombre</label>
            <input
              id="nombre"
              name="form-nombre"
              placeholder={nombre}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label forhtml="form-zona">Zona</label>
            <input
              id="zona"
              type="text"
              name="form-zona"
              placeholder={zona}
              className=" form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-fill btn-danger" onClick={this.handleClose}>
            CANCELAR
          </Button>
          <Button
            className="btn-fill btn-success"
            onClick={() =>
              this.editarSensor(
                id,
                document.getElementById("nombre").value,
                document.getElementById("zona").value
              )
            }
          >
            GUARDAR
          </Button>
        </Modal.Footer>
      </Modal>
    );
    var modalCurtain = (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Body>
          <div className="form-group">
            <label forhtml="form-nombre">Nombre</label>
            <input
              id="nombre"
              name="form-nombre"
              placeholder={nombre}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label forhtml="form-zona">Zona</label>
            <input
              id="zona"
              type="text"
              name="form-zona"
              placeholder={zona}
              className=" form-control"
            />
          </div>
          <div className="form-group">
            <label forhtml="form-direction">Reverse</label>
            <div>
              <Toggle
                id="reverse"
                defaultChecked={reverse == 0 ? false : true}
                onChange={e => this.handleReverse(e.target.checked)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-fill btn-danger" onClick={this.handleClose}>
            CANCELAR
          </Button>
          <Button
            className="btn-fill btn-success"
            onClick={() =>
              this.editarSensor(
                id,
                document.getElementById("nombre").value,
                document.getElementById("zona").value,
                null,
                this.state.curtainReverse,
              )
            }
          >
            GUARDAR
          </Button>
        </Modal.Footer>
      </Modal>
    );

    var modalHistorics = (
      <Modal id={"modalHistorics"} key={id + "modalHistorics"} show={this.state.showHistoricPopup} onHide={this.handleClose}>
        <Modal.Body>
          <HistoricsGraph id={id} sensor={this.props.sensor}></HistoricsGraph>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-fill btn-sm btn" onClick={this.handleClose}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
    );

    var editar = (
      <i onClick={() => this.handleShowEdit(id)} className="fas fa-cog editarIconoDispositivo" />
    );

    var historicsPopup = (
      <div onClick={() => this.handleShowHistorics(id)} className="HistoricsIconoDispositivo"> <i className="fas fa-poll HistoricsIconoDispositivo" /> Historico</div>
    );

    /////////SENSOR QUE CONTROLA UN RELE/////////////
    if (tipo === "SONOFF" || tipo === "ZB_SWITCH") {
      let iconoEstado = "";
      let potencia = "";

      if (status === 1) {
        if (estado !== null && estado !== undefined) {
          if (estado === 0) {
            iconoEstado = (
              <i
                onClick={() => postSensor(id, "TURN_ON")}
                className={deviceType.toLowerCase() == "luz" ? "cursorPointer pe-7s-light  text-danger" : "cursorPointer pe-7s-less text-danger"}
              ></i>
            );
            potencia = (
              <p className="potencia">
                <i className="iconoCards fa fa-bolt"></i>0W
              </p>
            );
          } else {
            iconoEstado = (
              <i onClick={() => postSensor(id, "TURN_OFF")} className={deviceType.toLowerCase() == "luz" ? "cursorPointer pe-7s-light  text-success" : "cursorPointer pe-7s-power text-success"} />
            );
            potencia = (
              <p className="potencia">
                <i className="iconoCards fa fa-bolt"></i>
                {power}W
              </p>
            );
          }
        }
      } else {
        iconoEstado = (
          <i id={id} className="cursorPointer pe-7s-close-circle" style={{ color: "#b7b7b7" }} />
        );
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <PowCard
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className={deviceType.toLowerCase() == "luz" ? "pe-7s-light" : "pe-7s-power"}></i>}
            // statsIconText={"ACCSA_ON/OFF"}
            statsIconText={piso + "-" + zona}
            // dato1={potencia}
            editar={editar}
            historics={historicsPopup}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            linkQuality={linkQuality}
            status={status}
          />
          {modalConPow}
          {modalHistorics}
        </div>
      );
    }

    /////////SENSOR SONOFF POW/////////////
    if (tipo === "SONOFF_POW" || tipo === "SONOFF_POW_Elite") {
      let iconoEstado = "";

      const tooltipPers = (
        <Tooltip id={tipo + id}>
          <p>
            <i className=" fa fa-bolt"></i> Potencia: {power} W
          </p>
          <p>
            <i className=" fa fa-bolt"></i> Voltaje: {voltage} V
          </p>
          <p>
            <i className=" fa fa-bolt"></i> Corriente: {current} A
          </p>
          <p>
            <i className=" fa fa-bolt"></i> Factor: {factor}
          </p>
          <p>
            <i className=" fa fa-bolt"></i> Consumo: {today} Kwh
          </p>
        </Tooltip>
      );
      if (status === 1) {
        if (estado !== null && estado !== undefined) {
          if (estado === 0) {
            iconoEstado = (
              <i id={id} onClick={() => postSensor(id, "TURN_ON")} className="cursorPointer pe-7s-less  text-danger" />
            );
          } else {
            iconoEstado = (
              <i id={id} onClick={() => postSensor(id, "TURN_OFF")} className="cursorPointer pe-7s-power  text-success" />
            );
          }
        }
      } else {
        iconoEstado = (
          <i id={id} className="cursorPointer pe-7s-close-circle " style={{ color: "#b7b7b7" }} />
        );
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <PowCard
            historics={historicsPopup}
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className=" fa fa-bolt"></i>}
            // statsIconText={"ACCSA_POW"}
            statsIconText={piso + "-" + zona}
            power={power}
            factor={factor}
            voltage={voltage}
            current={current}
            dato1={
              <p className="potencia">
                <i className="iconoCards fa fa-bolt"></i>
                {power}W
              </p>
            }
            editar={editar}
            status={status}
            masInfo={
              <OverlayTrigger placement="top" overlay={tooltipPers}>
                <i className="cursorPointer fa fa-info float-left"></i>
              </OverlayTrigger>
            }
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }

    //////////////// PIR SONOFF////////////////
    if (tipo === "RF_PIR" || tipo === "ZB_PIR") {
      let iconoEstado = "";
      if (estado !== null && estado !== undefined) {
        if (estado === 1) {
          iconoEstado = <i id={id} className=" fa fa-running  text-danger"></i>;
        }
        if (estado === 0) {
          iconoEstado = <i id={id} className=" fa fa-male  text-success"></i>;
        }
      } else {
        iconoEstado = <i id={id} className=" pe-7s-delete-user  text-grey"></i>;
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <SimpleCard
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className="fa fa-running"></i>}
            // statsIconText={tipo}
            statsIconText={piso + "-" + zona}
            historics={historicsPopup}
            editar={editar}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            linkQuality={linkQuality}
            status={status}
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }

    //////////////// ZB DOOR SONOFF / mediante sonoff Bridge////////////////
    if (tipo === "ZB_MAGNETIC") {
      let iconoEstado = "";

      if (estado !== null && estado !== undefined) {
        if (estado === 1) {
          iconoEstado = (
            <i id={id} className=" fa fa-door-open  text-danger"></i>
          );
        }
        if (estado === 0) {
          iconoEstado = (
            <i id={id} className=" fa fa-door-closed  text-success"></i>
          );
        }
      } else {
        iconoEstado = (
          <i id={id} className=" pe-7s-close-circle   text-grey"></i>
        );
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <SimpleCard
            historics={historicsPopup}
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={
              <i
                className={
                  estado == 0 ? "fas fa-door-closed" : "fas fa-door-open"
                }
              ></i>
            }
            // statsIconText={tipo}
            statsIconText={piso + "-" + zona}
            editar={editar}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            linkQuality={linkQuality}
            status={status}
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }

    //////////////// ZB BUTTON SONOFF / mediante sonoff Bridge////////////////
    if (tipo === "ZB_BUTTON") {
      let iconoEstado = "";

      if (estado !== null && estado !== undefined) {
        if (estado === 1) {
          iconoEstado = <i id={id} className=" pe-7s-target  text-success"></i>;
        }
        if (estado === 0) {
          iconoEstado = <i id={id} className=" pe-7s-target text-info"></i>;
        }
      } else {
        iconoEstado = <i id={id} className=" pe-7s-delete-user  text-grey"></i>;
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <SimpleCard
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className="pe-7s-target"></i>}
            // statsIconText={tipo}
            statsIconText={piso + "-" + zona}
            historics={historicsPopup}
            editar={editar}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            status={status}
            linkQuality={linkQuality}
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }
    /////////SENSOR DE MOVIMIENTO/////////////
    if (tipo === "RF_REMOTE") {
      let iconoEstado = "";

      if (estado !== null && estado !== undefined) {
        if (estado === 0) {
          iconoEstado = (
            <i id={id} onClick={(e) => postSensor(e.target.id, "TURN_ON")} className="cursorPointer fas fa-toggle-off  text-danger" />
          );
        } else {
          iconoEstado = (
            <i id={id} onClick={(e) => postSensor(e.target.id, "TURN_OFF")} className="cursorPointer fas fa-toggle-on   text-success" />
          );
        }
      } else {
        iconoEstado = <i id={id} className=" pe-7s-delete-user  text-grey"></i>;
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <SimpleCard
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className="pe-7s-light"></i>}
            // statsIconText={"ACCSA_REMOTE"}
            statsIconText={piso + "-" + zona}
            historics={historicsPopup}
            editar={editar}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            status={status}
            linkQuality={linkQuality}
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }

    ///////// IR CONTROLADOR/////////////
    if (tipo === "IR_BRIDGE") {
      let atributoValor = this.props.sensor._associatedDevices.map(
        (sensor) => sensor.id
      );
      let sinRepetidos = atributoValor.filter(
        (valor, indiceActual, arreglo) =>
          arreglo.indexOf(valor) === indiceActual
      );
      let retorno = sinRepetidos.map((subID) => (
        <div key={subID + h} className="col-lg-2 col-sm-3 col-xs-6">
          <RemoteControl
            id={this.props.sensor._id}
            valores={this.props.sensor._associatedDevices.filter(
              (valor) => valor.id === subID
            )}
            statsValue={deviceType + "-" + subID}
            statsIcon={<i className="pe-7s-keypad"></i>}
            historics={historicsPopup}
            statsIconText={piso + "-" + zona}
          // editar={editar}
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      ));
      return retorno;
    }

    //////////////// ZB_TH temperatura y humedad conectado a un bridge////////////////
    if (tipo === "ZB_TH" || tipo === "NETTRA_TH") {
      let iconoEstado = "";

      // iconoEstado = <i id={id} class="fas fa-thermometer-half text-success" text-success></i>
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <TempCard
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            temp={temperatura}
            hum={humedad}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className="fa fa-thermometer-half"></i>}
            // statsIconText={tipo}
            statsIconText={piso + "-" + zona}
            editar={editar}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            historics={historicsPopup}
            linkQuality={linkQuality}
            status={status}
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }

    //////////////// ZB_AIRQTUYA calidad de aire////////////////
    if (tipo === "ZB_AIRQ_TUYA") {
      let iconoEstado = "";

      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <AirQCard
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            temp={temperatura / 10}
            hum={humedad / 10}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className="fas fa-wind"></i>}
            // statsIconText={tipo}
            statsIconText={piso + "-" + zona}
            editar={editar}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            historics={historicsPopup}
            linkQuality={linkQuality}
            co2={CO2}
            hcho={HCHO}
            status={status}
            voc={VOC}
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }

    /////////SENSOR SONOFF DUAL R3/////////////
    if (tipo === "SONOFF_DUALR3") {
      let iconoEstado = "";
      let iconoEstado2 = "";
      const tooltipPers = (
        <Tooltip id={tipo + id}>
          <p><i className=" fa fa-bolt"></i> PotenciaS1: {powers[0]} W</p>
          <p><i className=" fa fa-bolt"></i> PotenciaS2: {powers[1]} W</p>
          <p><i className=" fa fa-bolt"></i> Voltaje: {voltage} V</p>
          <p><i className=" fa fa-bolt"></i> CorrienteS1: {currents[0]} A</p>
          <p><i className=" fa fa-bolt"></i> CorrienteS2: {currents[1]} A</p>
          <p><i className=" fa fa-bolt"></i> FactorS1: {factors[0]}</p>
          <p><i className=" fa fa-bolt"></i> FactorS2: {factors[1]}</p>
          <p><i className=" fa fa-bolt"></i> Consumo: {today} Kwh
          </p>
        </Tooltip>
      );
      if (status === 1) {
        if (estados !== null && estados !== undefined) {
          if (estados[0] === 0) {
            iconoEstado = (
              <>
                <i id={id} onClick={() => postSensor(id, "TURN_ON_1")} className="cursorPointer pe-7s-less  text-danger" />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>SW1</div>
              </>
            );
          } else {
            iconoEstado = (
              <>
                <i id={id} onClick={() => postSensor(id, "TURN_OFF_1")} className="cursorPointer pe-7s-power  text-success" />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>SW1</div>
              </>
            );
          }
          if (estados[1] === 0) {
            iconoEstado2 = (
              <>
                <i d={id} onClick={() => postSensor(id, "TURN_ON_2")} className="cursorPointer pe-7s-less  text-danger" />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>SW2</div>
              </>
            );
          } else {
            iconoEstado2 = (
              <>
                <i id={id} onClick={() => postSensor(id, "TURN_OFF_2")} className="cursorPointer pe-7s-power  text-success" />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>SW2</div>
              </>
            );
          }
        }
      } else {
        iconoEstado = (
          <i id={id} className="cursorPointer pe-7s-close-circle " style={{ color: "#b7b7b7" }} />
        );
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <PowCard
            bigIcon={iconoEstado}
            bigIcon2={iconoEstado2}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className=" fa fa-bolt"></i>}
            // statsIconText={"ACCSA_POW"}
            statsIconText={piso + "-" + zona}
            power={powers}
            factor={factors}
            historics={historicsPopup}
            voltage={voltage}
            current={currents}
            editar={editar}
            masInfo={
              <OverlayTrigger placement="top" overlay={tooltipPers}>
                <i className="cursorPointer fa fa-info float-left"></i>
              </OverlayTrigger>
            }
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }


    /////////CORTINA/////////////
    if (tipo === "ZB_CURTAIN") {
      let iconoEstado3 = "";
      let iconoEstado2 = "";
      let iconoEstado = "";

      if (status === 1) {
        if (estado !== null && estado !== undefined) {
          if (estado === 0) {
            iconoEstado = (
              <>
                <i onClick={() => postSensor(id, "OPEN")} className="cursorPointer pe-7s-angle-up-circle " />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>UP</div>
              </>
            );
            iconoEstado2 = (
              <>
                <i className={"pe-7s-less  text-info"} />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>STOP</div>
              </>
            );
            iconoEstado3 = (
              <>
                <i onClick={() => postSensor(id, "CLOSE")} className="cursorPointer pe-7s-angle-down-circle  " />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>DOWN</div>
              </>
            );
          } else if (estado == 2) {
            iconoEstado = (
              <>
                <i className={"pe-7s-angle-up-circle " + [reverse === 0 ? " text-info" : ""]} />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>UP</div>
              </>
            );
            iconoEstado2 = (
              <>
                <i onClick={() => postSensor(id, "STOP")} className="cursorPointer pe-7s-less  " />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>STOP</div>
              </>
            );
            iconoEstado3 = (
              <>
                <i onClick={() => postSensor(id, "CLOSE")} className={"cursorPointer pe-7s-angle-down-circle " + [reverse === 0 ? "" : " text-info"]} />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>DOWN</div>
              </>
            );
          } else if (estado == 1) {
            iconoEstado = (
              <>
                <i onClick={() => postSensor(id, "OPEN")} className={"cursorPointer pe-7s-angle-up-circle " + [reverse === 0 ? "" : " text-info"]} />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>UP</div>
              </>
            );
            iconoEstado2 = (
              <>
                <i onClick={() => postSensor(id, "STOP")} className="cursorPointer pe-7s-less  " />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>STOP</div>
              </>
            );
            iconoEstado3 = (
              <>
                <i className={" pe-7s-angle-down-circle  " + [reverse === 0 ? " text-info" : ""]} />
                <div style={{ fontSize: "1.2rem", marginTop: "-2rem", fontWeight: "bold" }}>DOWN</div>
              </>
            );
          }
        }
      } else {
        iconoEstado = (
          <i id={id} className="cursorPointer pe-7s-close-circle" style={{ color: "#b7b7b7" }} />
        );
      }
      return (
        <div key={id + h} className="col-lg-4 col-sm-6 col-xs-12">
          <CortinaCard
            bigIcon2={iconoEstado2}
            bigIcon3={iconoEstado3}
            bigIcon={iconoEstado}
            historics={historicsPopup}
            position={position}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className="far fa-window-maximize"></i>}
            // statsIconText={"ACCSA_ON/OFF"}
            statsIconText={piso + "-" + zona}
            // dato1={potencia}
            editar={editar}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            status={status}
            linkQuality={linkQuality}
          />
          {modalHistorics}
          {modalCurtain}
        </div>
      );
    }

    //////////////// SMOKE SENSOR////////////////
    if (tipo === "RF_SMOKE" || tipo === "ZB_SMOKE_TUYA") {
      let iconoEstado = "";
      let nuevoEstado = null

      if (estado !== null && estado !== undefined) {
        if (estado === 1) {
          iconoEstado = <i id={id} className=" fab fa-gripfire  text-danger"></i>;
          nuevoEstado = <i onClick={() => this.editarSensor(id, null, null, null, null, 0)} className=" cursorPointer fas fa-power-off  text-info"></i>
        }
        if (estado === 0) {
          // nuevoEstado = <i onClick={() => this.editarSensor(id, null, null, null, null, 1)} className="cursorPointer fas fa-check-circle  text-danger"></i>
          iconoEstado = <i id={id} className=" fab fa-gripfire  text-success"></i>;
        }
      } else {
        iconoEstado = <i id={id} className=" pe-7s-delete-user  text-grey"></i>;
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <SimpleCard
            bigIcon={iconoEstado}
            statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className="fab fa-gripfire"></i>}
            // statsIconText={tipo}
            statsIconText={piso + "-" + zona}
            historics={historicsPopup}
            editar={editar}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            linkQuality={linkQuality}
            tamper={this.props.sensor._tamper}
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }

    /////////LED TIRA WLED/////////////
    if (tipo === "WLED") {
      let iconoEstado = "";
      let potencia = "";


      if (status === 1) {
        if (estado !== null && estado !== undefined) {
          if (estado === 0) {
            iconoEstado = (
              <i
                onClick={() => postSensor(id, "TURN_ON")}
                className="cursorPointer pe-7s-less  text-danger"
              ></i>
            );
            potencia = (
              <p className="potencia">
                <i className="iconoCards fa fa-bolt"></i>0W
              </p>
            );
          } else {
            iconoEstado = (
              <i onClick={() => postSensor(id, "TURN_OFF")} className="cursorPointer pe-7s-power  text-success" />
            );
            potencia = (
              <p className="potencia">
                <i className="iconoCards fa fa-bolt"></i>
                {power}W
              </p>
            );
          }
        }
      } else {
        iconoEstado = (
          <i id={id} className="cursorPointer pe-7s-close-circle" style={{ color: "#b7b7b7" }} />
        );
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <LedStrip
            postSensor={postSensor}
            sensor={this.props.sensor}
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className="pe-7s-light"></i>}
            // statsIconText={"ACCSA_ON/OFF"}
            statsIconText={piso + "-" + zona}
            dato1={potencia}
            editar={editar}
            batteryPercentage={batteryPercentage}
            batteryVoltage={batteryVoltage}
            historics={historicsPopup}
            linkQuality={linkQuality}
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }

    //////////////// NETTRA BRIDGE////////////////
    if (tipo === "NETTRA_BRIDGE") {
      let iconoEstado = "";
      const tooltipPers = (
        <Tooltip id={tipo + id}>
          {this.props.sensor._associatedDevices.map((dato, index) => {
            return (
              <p key={index + dato.id}>
                <small><i className=" fa fa-user"></i> ID Child: {dato.id}</small>
              </p>)
          })
          }
          <p>
            <i className=" fa fa-bolt"></i> Bateria: {this.props.sensor._batteryLevel} %
          </p>
          <p>
            <i className=" fa fa-bolt"></i> Estado Bateria: {this.props.sensor._batteryStatus == 2 ? "Sin Corriente" : "OK"}
          </p>
        </Tooltip>
      );
      if (status !== null && status !== undefined) {
        if (status === 0) {
          iconoEstado = <i id={id} className=" fa fa-network-wired  text-danger"></i>;
        }
        if (status === 1) {
          iconoEstado = <i id={id} className=" fa fa-network-wired  text-info"></i>;
        }
      }
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <SimpleCard
            bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className="fa fa-network-wired"></i>}
            // statsIconText={tipo}
            statsIconText={piso + "-" + zona}
            historics={historicsPopup}
            editar={editar}
            batteryPercentage={this.props.sensor._batteryLevel}
            batteryStatus={this.props.sensor._batteryStatus}
            linkQuality={linkQuality}
            status={status}
            masInfo={
              <OverlayTrigger placement="top" overlay={tooltipPers}>
                <i className="cursorPointer fa fa-info float-left"></i>
              </OverlayTrigger>
            }
          />
          {modalHistorics}
          {modalSinPow}
        </div>
      );
    }



    //////////////// SCHNEIDER_TAG////////////////
    if (tipo === "SCHNEIDER_TAG") {
      let iconoEstado = "";

      const tooltipPers = (
        <Tooltip id={tipo + id}>
          <p>
            <i className=" fa fa-bolt"></i> Potencia: {power} W
          </p>
          <p>
            <i className=" fa fa-bolt"></i> Voltaje: {voltage} V
          </p>
          <p>
            <i className=" fa fa-bolt"></i> Corriente: {current} A
          </p>
          <p>
            <i className=" fa fa-bolt"></i> Factor: {factor}
          </p>

        </Tooltip>
      );
      return (
        <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
          <PowCard
            historics={historicsPopup}
            // bigIcon={iconoEstado}
            // statsText={nuevoEstado}
            statsValue={deviceType + "-" + nombre}
            statsIcon={<i className=" fa fa-bolt"></i>}
            // statsIconText={"ACCSA_POW"}
            statsIconText={piso + "-" + zona}
            power={power}
            factor={factor}
            voltage={voltage}
            current={current}
            dato1={
              <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "2fr 1fr",
                justifyItems: "start"
              }}>
                <p style={{ color: "blue" }}>
                  <strong>{power}W</strong>
                </p>
                <p style={{ color: "blueviolet" }}>
                  <strong>{voltage}V</strong>
                </p >
                <p style={{ color: "purple" }}>
                  <strong>{current}A</strong>
                </p>
                <p style={{ color: "darkmagenta" }}>
                  <strong>FP {factor}</strong>
                </p>
              </div >
            }
            editar={editar}
            status={status}
            masInfo={
              <OverlayTrigger OverlayTrigger placement="top" overlay={tooltipPers} >
                <i className="cursorPointer fa fa-info float-left"></i>
              </OverlayTrigger >
            }
          />
          {modalHistorics}
          {modalSinPow}
        </div >
      );
    }

//////////////// NETTRA BRIDGE////////////////
if (tipo === "WEATHERSTATION") {
    const getWindDirection = (windNumber) => {
      const directions = {
        0: 'Norte', // Cambiado de Norte a Sur
        45: 'Noreste',
        90: 'Este',
        135: 'Sureste',
        180: 'Sur', // Cambiado de Sur a Norte
        225: 'Suroeste',
        270: 'Oeste',
        315: 'Noroeste',
      };
  
      // Encontrar la dirección más cercana en base al número
      const closestDirection = Object.keys(directions).reduce((a, b) =>
        Math.abs(Number(b) - windNumber) < Math.abs(Number(a) - windNumber) ? b : a
      );
  
      return directions[closestDirection];
    };
  const tooltipPers = (
    <Tooltip id={tipo + id}>
    <p>
      Temperatura: {temperatura}
    </p>
    <p>
      Humedad: {humedad}
    </p>
    <p>
      Humedad Relativa: {relative_humidity}
    </p>
    <p>
      Punto de rocio: {dewpoint}
    </p>
    <p>
      Presion: {pressure}
    </p>
    <p>
     Luz: {light}
    </p>
    <p>
     Lluvia: {rain}
    </p>
    <p>
     Direccion Viento: {getWindDirection(wind_direction)}
    </p>
    <p>
     Velocidad Viento: {wind_speed}
    </p>
  </Tooltip>
  );
  
  return (
    <div key={id + h} className="col-lg-2 col-sm-3 col-xs-6">
      <WeatherStation
      sensor={this.props.sensor}
        statsValue={deviceType + "-" + nombre}
        statsIcon={<i className="fa fa-network-wired"></i>}
        // statsIconText={tipo}
        statsIconText={piso + "-" + zona}
        historics={historicsPopup}
        editar={editar}
        batteryPercentage={this.props.sensor._batteryLevel}
        batteryStatus={this.props.sensor._batteryStatus}
        linkQuality={linkQuality}
        status={status}
        masInfo={
          <OverlayTrigger placement="top" overlay={tooltipPers}>
            <i className="cursorPointer fa fa-info float-left"></i>
          </OverlayTrigger>
        }
      />
      {modalHistorics}
      {modalSinPow}
    </div>
  );
}

  }
  render() {
    var dato = this.armarTarjetaSensor();
    return <>{dato}</>;
  }
}
