import Dashboard from "views/Dashboard.jsx";
import Dispositivos from "views/Dispositivos.jsx"
import Reglas from "views/Reglas";
import Plano from "views/Plano"
import Registros from "views/Regristros"
import SceneList from "views/Escenarios"
import Historicos from "views/Historicos"

const dashboardRoutes = [
  {
    path: "/dispositivos",
    name: "Dispositivos",
    icon: "pe-7s-usb",
    component: Dispositivos,
    layout: "/admin"
  },
  {
    path: "/plano",
    name: "Plano",
    icon: "pe-7s-map-2",
    component: Plano,
    layout: "/admin"
  },
  {
    path: "/reglas",
    name: "Reglas",
    icon: "pe-7s-hammer",
    component: Reglas,
    layout: "/admin"
  },
  {
    path: "/scenes",
    name: "Escenarios",
    icon: "pe-7s-helm",
    component: SceneList,
    layout: "/admin"
  },
  // {
  //   path: "/historicos",
  //   name: "Estadísticas",
  //   icon: "pe-7s-graph1",
  //   component: Historicos,
  //   layout: "/admin"
  // },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "pe-7s-graph",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  {
    path: "/registros",
    name: "Registros",
    icon: "pe-7s-note2",
    component: Registros,
    layout: "/admin"
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "pe-7s-user",
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "pe-7s-note2",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "pe-7s-news-paper",
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "pe-7s-science",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "pe-7s-map-marker",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "pe-7s-bell",
  //   component: Notifications,
  //   layout: "/admin"
  // },
  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "pe-7s-rocket",
  //   component: Upgrade,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
