import React, { Component } from 'react'
import MaterialTable from 'material-table';
import config from "../config"
import { traerLogs } from "../Services/Services"
export default class Regristros extends Component {
    constructor(props) {
        super(props)
        this.state = {
            logs: []
        }
    }
     componentDidMount() {
         traerLogs().then(
            logs => {
                this.setState({ logs });
            });
    }
    render() {
        return (
            <div>
                <div className="text-center h3">Registros <i className="pe-7s-note2" style={{ marginBottom: "3rem" }}></i>
                    <div className="marginauto" style={{ width: "98%" }}>
                        <MaterialTable
                            title="Ultimos registros"
                            columns={[
                                { title: "", cellStyle: { textAlign: 'center' }, field: 'Usuario', render: rowData => <img alt="imguser" src={config.baseUrl+"/faces/" + rowData.trigger + ".jpg"} style={{ width: 30, borderRadius: '50%' }} /> },
                                { title: 'Usuario', field: 'trigger' },
                                { title: 'description', field: 'description' },
                                { title: 'Fecha', field: 'datetime', render: rowData => new Date(rowData.datetime).toLocaleString() },
                            ]}
                            data={this.state.logs}
                            options={{sorting: true}}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
