import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
// import "./PowCard.css";

export class CortinaCard extends Component {
  render() {
    return (
      <div className="card card-stats">
        {this.props.editar}
        <div style={{ padding: "4px 0px 0px 4px", position: "absolute", fontSize: "1.1rem" }}>
          {this.props.linkQuality && this.props.linkQuality >= 0 || this.props.status >= 0 ? (

            <i
              className={
                this.props.status === 0
                ? "fas fa-wifi text-muted"
                :this.props.linkQuality === 0 
                ? "fas fa-wifi text-muted"
                  : this.props.linkQuality < 11
                    ? "fas fa-wifi text-danger"
                    : this.props.linkQuality < 41
                      ? "fas fa-wifi text-warning"
                      : this.props.linkQuality
                        ? "fas fa-wifi text-success"
                        :this.props.status >0
                        ? "fas fa-wifi text-success"
                          : null}
            ></i>
          ) : null}
          {this.props.batteryPercentage >= 0 ? (
            <div >
              <i
                className={
                  this.props.batteryStatus == 2
                    ? "fas fa-plug text-muted"
                    : this.props.batteryPercentage === 0
                      ? "fas fa-battery-quarter text-muted"
                      : this.props.batteryPercentage < 11
                        ? "fas fa-battery-quarter text-danger"
                        : this.props.batteryPercentage < 51
                          ? "fas fa-battery-half text-warning"
                          : this.props.batteryPercentage < 76
                            ? "fas fa-battery-three-quarters text-success"
                            : this.props.batteryPercentage
                              ? "fas fa-battery-full text-success"
                              : null
                }
              ></i>
              <div className="text-muted"><small>{this.props.batteryPercentage}%</small></div>
            </div>
          ) : null}

        </div>
        <div className="content">
          <Row>
            {this.props.bigIcon ? <Col xs={4}>
              <div className="icon-big text-center icon-warning">
                {this.props.bigIcon}
              </div>
            </Col> : null}
            {this.props.bigIcon2 ? (
              <Col xs={4}>
                <div className="icon-big text-center icon-warning">
                  {this.props.bigIcon2}
                </div>
              </Col>
            ) : null}
            {this.props.bigIcon3 ? (
              <Col xs={4}>
                <div className="icon-big text-center icon-warning">
                  {this.props.bigIcon3}
                </div>
              </Col>
            ) : null}
            {this.props.dato1 ? (
              <Col xs={8}>
                <div className="numbers estiloPowCard">
                  {this.props.dato1}
                  {this.props.dato2}
                  {this.props.dato3}
                </div>
              </Col>
            ) : null}
            <Col xs={12}>
              <div className="numbers">{this.props.statsValue}</div>
            </Col>
          </Row>
          <div className="footer ">
            <hr />
            <div className="stats">
              {this.props.statsIcon} {this.props.statsIconText}
              {this.props.masInfo}
              {this.props.position == 0 ? <span style={{ float: 'right' }}>Closed</span>
                : this.props.position > 0 ? <span style={{ float: 'right' }}>Open {this.props.position * 10}%</span> : null}
            </div>
          </div>
          {this.props.historics ? this.props.historics : <div />}
        </div>
      </div>
    );
  }
}

export default CortinaCard;
