import React, { Component } from 'react'
import Draggable from 'react-draggable';
import * as io from 'socket.io-client';
import "./PlanoComponent.css"
import config from "../../config";
import { filtrarSelect } from "../../Utils/Utils";
import { traerSensores, postSensor, patchSensor } from "../../Services/Services";
import LedStripPlano from 'LedStripPlano/LedStripPlano';
export default class PlanoComponent extends Component {

    //// ICONOS SEGUN EL TIPO ///
    LUZ = "far fa-lightbulb "
    DUAL = " pe-7s-less "
    AC = "fas fa-wind "
    VENT = "fas fa-fan "
    DA = "fas fa-water "
    PIR = "fas fa-"
    REMOTE = "fas fa-toggle-on "
    CORTINA = "fas fa-toggle-on "
    TURBINA = "fas fa-fan "
    DOOR = "fas fa-door-"
    SMOKE = "fab fa-gripfire"
    CAFE = "fas fa-coffee"
    DR = "fas fa-snowflake"
    ////////////////////////////////

    constructor(props) {
        super(props);
        this.imageRef = React.createRef()
        this.updateDimensions = this.updateDimensions.bind(this)
        this.state = {
            dragHabilitado: false,
            activeDrags: 100,
            positionActive: {
                x: 0, y: 0
            },
            wImagen: null,
            hImagen: null,

            sensores: [],
            sensoresModificadosCoords: [],
            piso: "",
            edificios: [],
            edifbuffer: [],
        };
    }
    //// AL CAMBIAR LA DIMENSION DE LA PANTALLA, ESTA ES GUARDADA EN EL ESTADO ////
    updateDimensions() {
        if (this.imageRef.current) {
            let wImagen = this.imageRef.current.width
            let hImagen = this.imageRef.current.height

            this.setState({ wImagen, hImagen })
        }
    }

    ////// PASA LAS COORDENADAS DE PORCENTAJE A PIXELES ////////
    parseoCoords(valor) {

        if (valor !== null && valor !== undefined && valor !== "") {
            let coordenadas = JSON.parse(valor)
            let wImagen = this.state.wImagen ? this.state.wImagen : this.imageRef.current.width
            let hImagen = this.state.hImagen ? this.state.hImagen : this.imageRef.current.height
            let coordnuevas = {
                "x": Math.floor(coordenadas[0] * wImagen),
                "y": Math.floor(-coordenadas[1] * hImagen)
            }
            return coordnuevas
        } else {
            return "[0,0]"
        }
    }
    //// AL DETENER EL MOIVIMIENTO DEL ICONO, SETEA EL ESTADO DE LOS SENSORES CON SUS NUEVAS COORDENADAS //
    onStop = (e, data, id) => {
        let xAbsoluta = data.x
        let xImagen = this.imageRef.current.width
        let xRelativa = xAbsoluta / xImagen;
        let yAbsoluta = data.y
        let yImagen = this.imageRef.current.height
        let yRelativa = -yAbsoluta / yImagen;
        let sensores = this.state.sensores;
        for (let sensor of sensores) {
            if (sensor._id === id) {
                sensor._coords = `[${xRelativa}, ${yRelativa}]`
            }
        }
        this.setState({ sensores })
    };

    crearSocket() {
        this.socket = io(config.socketUrl);
        this.socket.on('newData', data => {
            let nuevos = JSON.parse(data)
            let viejos = this.state.sensores
            let sensores = this.combinarSensores(nuevos, viejos)
            this.setState({ sensores })
        })
        const service = localStorage.getItem("service")
        const token = localStorage.getItem("token")
        this.socket.emit('discover', { service, token })
    }
    componentWillMount() {
    }
    componentDidMount() {
        traerSensores().then(
            sensores => {
                // this.setState({ sensores, dragHabilitado: false, piso: sensores.reverse()[0].path });
                this.crearSocket();
                let sensorSuc = sensores.map((sensor, h) => {
                    return sensor["path"].split("/")[0]
                })
                let sucSinRepetidos = sensorSuc.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
                this.setState({ sensores, dragHabilitado: false, piso: sensores[0].path.split("/")[1], edificios: sucSinRepetidos, edifbuffer: sucSinRepetidos });
                setTimeout(() => { this.updateDimensions() }, 600);

            });
        window.addEventListener('resize', this.updateDimensions);
        // this.updateDimensions()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    //// ACTUALIZA UBICACION EN EL SOCKET AL GUARDAR NUEVAS COORDENADAS ////
    combinarSensores(nuevos, viejos) {
        for (let i = 0; i < nuevos.length; i++) {
            let idNuevo = nuevos[0]._id
            let coordsViejo = viejos.filter(viejo => viejo._id === idNuevo)[0]._coords
            nuevos[i]._coords = coordsViejo
            return nuevos
        }
    }

    ///// GUARDA LA COORDENADA HACIENDO UN PATCH AL SERVIDOR/////
    async guardoCoords() {
        let sensores = this.state.sensores;
        await sensores.map((sensor) => {
            patchSensor("coords", sensor._id, sensor._coords)
        })
        this.setState({ dragHabilitado: !this.state.dragHabilitado })
    }

    //// VUELVE LOS ICONOS A SU UBICACION ORIGINAL /////////
    resetMapa() {
        traerSensores().then(
            sensores => {
                this.setState({ sensores, dragHabilitado: false });
            });
    }



    ///// RECOGE LOS PISOS QUE TIENE CADA SENSOR Y MUESTRA UN SELECT//////
    SelectDinamic(atributo) {
        let sensores = this.state.sensores
        let tipos = []

        for (let i in sensores) {
            if (sensores[i][atributo].split("/")[0] == this.state.edificios.toString()) {
                tipos.push(sensores[i][atributo].split("/")[1])
            }
        }
        // let tipos = sensores.map((sensor, h) => {
        //     // console.log(sensor[atributo].split("/")[0],this.state.edificios.toString());
        //     if (sensor[atributo].split("/")[0] === this.state.edificios.toString()) {
        //         return sensor[atributo].split("/")[1]
        //     }else return
        // })
        let sinRepetidos = tipos.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
        if (sinRepetidos.length < 2) {
            return null
        } else {
            return (
                <select className="cursorPointer SelectPlanos" value={this.state.piso} onChange={e => this.setState({ piso: e.target.value })}>
                    {sinRepetidos.reverse().map((tipo, h) => {
                        return <option key={tipo + h} value={tipo}>{tipo}</option>
                    })}
                </select>)

        }
    }
    handlePostSensor(id, action) {
        if (!this.state.dragHabilitado)
            postSensor(id, action)
        console.log("cd3234dsfr/" + id + "/dsa3226da#ds");
    }


    ///// SEGUN EL TYPE, CREA LOS ICONOS POR SENSOR ///////
    amarIconoSensores() {
        let sensores = this.state.sensores
        let piso = this.state.piso
        let edificio = this.state.edificios
        sensores = filtrarSelect(edificio, piso, sensores, "path")
        if (sensores && this.imageRef.current) {

            return sensores.map((sensor) => {
                let coords = sensor._coords
                let deviceType = sensor._device_type
                let id = sensor._id
                let tipo = sensor._type
                let estado = sensor._state
                let estados = sensor._states
                let status = sensor._status
                let reverse = sensor._reverse
                let position = sensor._position
                let CO2 = sensor._CO2
                let iconoEstado = ""
                let coord = this.parseoCoords(coords);
                //SONOFF ICONO EN MAPA//
                if (tipo === "SONOFF" || tipo === "SONOFF_POW" || tipo === "ZB_SWITCH") {
                    if (status === 1) {
                        if (estado !== null && estado !== undefined) {
                            if (estado === 0) {
                                iconoEstado = <i onClick={() => this.handlePostSensor(id, "TURN_ON")} className={"cursorPointer fa-2x text-center " + this[deviceType.toUpperCase()] + " " + deviceType.toUpperCase() + "OFF"}  >
                                </i>
                            } else {
                                iconoEstado = <i onClick={() => this.handlePostSensor(id, "TURN_OFF")} className={"cursorPointer fa-2x text-center " + this[deviceType.toUpperCase()] + " " + deviceType.toUpperCase() + "ON"}  >
                                </i>
                            }
                        }
                    } else {
                        iconoEstado = <i className={this[deviceType.toUpperCase()]} style={{ color: "white" }}></i>
                    }
                }

                //PIR SONOFF ICONO EN MAPA//
                if (tipo === "RF_PIR" || tipo === "ZB_PIR") {
                    if (estado !== null && estado !== undefined) {
                        if (estado === 0) {
                            iconoEstado = <i className={" fa-2x text-center " + this[deviceType.toUpperCase()] + "male" + " " + deviceType.toUpperCase() + "OFF"}  >
                            </i>
                        } else {
                            iconoEstado = <i className={" fa-2x text-center " + this[deviceType.toUpperCase()] + "running" + " " + deviceType.toUpperCase() + "ON"}  >
                            </i>
                        }
                    }
                }

                // ZB_TEMP ICONO EN MAPA//
                if (tipo === "ZB_TH" || tipo === "NETTRA_TH") {
                    // if (estado !== null && estado !== undefined) {
                    //     if (estado === 0) {
                    iconoEstado = <span className="tempstyle">
                        <span style={{ display: "inline-block" }}><i className="fas fa-thermometer-half"></i><span>{" " + sensor._temperature.toFixed(0) + "°C  "}  </span></span>
                        {sensor._humidity?<span style={{ display: "inline-block" }}>&nbsp;/ <i className="fab fa-empire"></i><span>{" " + sensor._humidity.toFixed(0) + "%"}</span></span>:null}
                    </span>
                    //     } else {
                    //         iconoEstado = <i className={"cursorPointer fa-2x text-center " + this[deviceType.toUpperCase()]+"running" + " " + deviceType.toUpperCase() + "ON"}  >
                    //         </i>
                    //     }
                    // }
                }

                //ZB_MAGNETIC  DOOR SONOFF BRIDGE EN MAPA//
                if (tipo === "ZB_MAGNETIC") {
                    if (estado !== null && estado !== undefined) {
                        if (estado === 0) {
                            iconoEstado = <i className={" fa-2x text-center " + this[deviceType.toUpperCase()] + "closed" + " " + deviceType.toUpperCase() + "OFF"}  >
                            </i>
                        } else {
                            iconoEstado = <i className={" fa-2x text-center " + this[deviceType.toUpperCase()] + "open" + " " + deviceType.toUpperCase() + "ON"}  >
                            </i>
                        }
                    }
                }

                //REMOTE SONOFF ICONO EN MAPA//
                if (tipo === "RF_REMOTE") {
                    if (estado !== null && estado !== undefined) {
                        if (estado === 0) {
                            iconoEstado = <i className={"cursorPointer fa-2x text-center "}  >
                            </i>
                        } else {
                            iconoEstado = <i className={"cursorPointer fa-2x text-center " + this[deviceType.toUpperCase()] + " " + deviceType.toUpperCase() + "ON"}  >
                            </i>
                        }
                    }
                }
                //SONOFF MULTICHANNEL ICONO EN MAPA//
                if (tipo === "SONOFF_DUALR3") {
                    let icono1 = ""
                    let icono2 = ""
                    if (status === 1) {
                        if (estados !== null && estados !== undefined) {
                            if (estados[0] === 0) {
                                icono1 = <i onClick={() => this.handlePostSensor(id, "TURN_ON_1")} className={"cursorPointer fa-3x text-center " + this[deviceType.toUpperCase()] + " text-danger"} style={{ fontWeight: "bold" }}  >
                                </i>
                            } else if ((estados[0] === 1)) {
                                icono1 = <i onClick={() => this.handlePostSensor(id, "TURN_OFF_1")} className={"cursorPointer fa-3x text-center " + this[deviceType.toUpperCase()] + " text-success"} style={{ fontWeight: "bold" }} >
                                </i>
                            }
                            if (estados[1] === 0) {
                                icono2 = <i onClick={() => this.handlePostSensor(id, "TURN_ON_2")} className={"cursorPointer fa-3x text-center " + this[deviceType.toUpperCase()] + " text-danger"} style={{ fontWeight: "bold" }} >
                                </i>
                            } else if ((estados[1] === 1)) {
                                icono2 = <i onClick={() => this.handlePostSensor(id, "TURN_OFF_2")} className={"cursorPointer fa-3x text-center " + this[deviceType.toUpperCase()] + " text-success"} style={{ fontWeight: "bold" }} >
                                </i>
                            }
                        }
                        iconoEstado = <span className="dualStyle ">
                            <div style={{ border: "solid", backgroundColor: "#ffffffe3" }}>{icono1}<div>SW1</div></div>
                            <div style={{ border: "solid", backgroundColor: "#ffffffe3" }}> {icono2}<div>SW2</div></div>
                        </span>
                    } else {
                        iconoEstado = <span className="dualStyle text-muted"><div>
                            <i className={" fa-3x text-center  " + this[deviceType.toUpperCase()]} style={{ fontWeight: "bold" }} />
                            <div>SW1</div></div><div>
                                <i className={" fa-3x text-center " + this[deviceType.toUpperCase()]} style={{ fontWeight: "bold" }} />
                                <div>SW2</div></div></span>
                    }
                }

                //REMOTE SONOFF ICONO EN MAPA//
                if (tipo === "ZB_CURTAIN") {
                    let totalHeightDiv = 88
                    let heightBarraEstado = ((position * 10) / 100) * totalHeightDiv
                    console.log(heightBarraEstado);
                    if (estado !== null && estado !== undefined) {
                        if (estado === 0) {
                            iconoEstado = <span className="curtainStyle ">
                                <div style={{ fontWeight: "bold" }}>{deviceType}</div>
                                <div>
                                    {/* <div style={{ background: "rgb(0 0 0 / 33%)", height: totalHeightDiv - heightBarraEstado + "%", borderRadius: "0 0 1rem 1rem", zIndex: "-1", width: "15%", position: "fixed", top: "auto", right: "0px" }}></div> */}
                                    <div style={{ border:"2px solid white",background: "#2b8ed0", height: totalHeightDiv - heightBarraEstado + "%", borderRadius: "0 0 .4rem .4rem", zIndex: "-1", width: "100%", position: "fixed", top: "auto", left: "0px" }}></div>
                                    <i onClick={() => this.handlePostSensor(id, "OPEN")} className={"cursorPointer fa-3x pe-7s-angle-up-circle text-center "} style={{ fontWeight: "bold", backgroundColor:"white", borderRadius: "50%"}} ></i>
                                    <i onClick={() => this.handlePostSensor(id, "STOP")} className={"cursorPointer fa-3x pe-7s-less text-center "} style={{ fontWeight: "bold", backgroundColor:"white", borderRadius: "50%" }}  ></i>
                                    <i onClick={() => this.handlePostSensor(id, "CLOSE")} className={"cursorPointer fa-3x pe-7s-angle-down-circle  text-center "} style={{ fontWeight: "bold", backgroundColor:"white", borderRadius: "50%" }}  ></i>
                                </div>
                            </span>
                        } else if (estado === 1) {
                            iconoEstado = <span className="curtainStyle ">
                                <div style={{ fontWeight: "bold" }}>{deviceType}</div>
                                <div>
                                <div style={{ border:"2px solid white",background: "#2b8ed0", height: totalHeightDiv - heightBarraEstado + "%", borderRadius: "0 0 .4rem .4rem", zIndex: "-1", width: "100%", position: "fixed", top: "auto", left: "0px" }}></div>
                                    <i onClick={() => this.handlePostSensor(id, "OPEN")} className={"cursorPointer fa-3x pe-7s-angle-up-circle text-center " + [reverse === 0 ? "" : " text-success"]} style={{ fontWeight: "bold", backgroundColor:"white", borderRadius: "50%" }} ></i>
                                    <i onClick={() => this.handlePostSensor(id, "STOP")} className={"cursorPointer fa-3x  pe-7s-less text-center "} style={{ fontWeight: "bold", backgroundColor:"white", borderRadius: "50%" }}  ></i>
                                    <i onClick={() => this.handlePostSensor(id, "CLOSE")} className={"cursorPointer fa-3x pe-7s-angle-down-circle text-center" + [reverse === 0 ? " text-success" : ""]} style={{ fontWeight: "bold", backgroundColor:"white", borderRadius: "50%" }}  ></i>
                                </div>
                            </span>
                        }
                        else if (estado === 2) {
                            iconoEstado = <span className="curtainStyle "><div>
                                <div style={{ fontWeight: "bold" }}>{deviceType}
                                </div>
                                <div style={{ border:"2px solid white",background: "#2b8ed0", height: totalHeightDiv - heightBarraEstado + "%", borderRadius: "0 0 .4rem .4rem", zIndex: "-1", width: "100%", position: "fixed", top: "auto", left: "0px" }}></div>
                                <i onClick={() => this.handlePostSensor(id, "OPEN")} className={"cursorPointer fa-3x pe-7s-angle-up-circle text-center " + [reverse === 0 ? " text-success" : ""]} style={{ fontWeight: "bold", backgroundColor:"white", borderRadius: "50%" }} ></i>
                                <i onClick={() => this.handlePostSensor(id, "STOP")} className={"cursorPointer fa-3x pe-7s-less text-center "} style={{ fontWeight: "bold", backgroundColor:"white", borderRadius: "50%" }} ></i>
                                <i onClick={() => this.handlePostSensor(id, "CLOSE")} className={"cursorPointer fa-3x  pe-7s-angle-down-circle text-center " + [reverse === 0 ? "" : " text-success"]} style={{ fontWeight: "bold", backgroundColor:"white", borderRadius: "50%" }} ></i>
                            </div>
                            </span>
                        }
                    }
                }

                //ZB_AIRQTUYA calidad de aire//
                if (tipo === "ZB_AIRQ_TUYA") {
                    // if (estado !== null && estado !== undefined) {
                    //     if (estado === 0) {
                    iconoEstado = <span className="airqstyle">
                        <span style={{ display: "inline-block", color: [CO2 > 800 ? "orange" : CO2 > 950 ? "red" : "green"] }}><span>{"Co2 " + CO2} </span></span>
                        {/* <span style={{ display: "inline-block" }}>&nbsp;<i className="fab fa-empire"></i><span>{" " + sensor._humidity.toFixed(0) + "%"}</span></span> */}
                    </span>
                    //     } else {
                    //         iconoEstado = <i className={"cursorPointer fa-2x text-center " + this[deviceType.toUpperCase()]+"running" + " " + deviceType.toUpperCase() + "ON"}  >
                    //         </i>
                    //     }
                    // }
                }

                // RF_SMOKE SONOFF ICONO EN MAPA//
                if (tipo === "RF_SMOKE" || tipo === "ZB_SMOKE_TUYA") {
                    if (estado !== null && estado !== undefined) {
                        if (estado === 0) {
                            iconoEstado = <i className={" fa-2x text-center " + this[deviceType.toUpperCase()] + " " + deviceType.toUpperCase() + "OFF"}  >
                            </i>
                        } else {
                            iconoEstado = <i className={" fa-2x text-center " + this[deviceType.toUpperCase()] + " " + deviceType.toUpperCase() + "ON"}  >
                            </i>
                        }
                    }
                }
                
                //WLED calidad de aire//
                if (tipo === "WLED") {
                    // if (estado !== null && estado !== undefined) {
                    //     if (estado === 0) {
                    iconoEstado =  <span className={`${estado!==0?"wledstyle":"wledstyleInaccessible wledstyle"}`}><LedStripPlano
                    postSensor={postSensor}
                      sensor={sensor}
                      dragable={this.state.dragHabilitado}
                    /></span>
                    //     } else {
                    //         iconoEstado = <i className={"cursorPointer fa-2x text-center " + this[deviceType.toUpperCase()]+"running" + " " + deviceType.toUpperCase() + "ON"}  >
                    //         </i>
                    //     }
                    // }
                }
                ///RETORNO Y DIBUJO EL ICONO EN EL MAPA SEGUN EL TIPO///
                if (iconoEstado !== "") {
                    return (
                        <Draggable id={id} disabled={!this.state.dragHabilitado} onStop={(e, data) => this.onStop(e.target, data, id)} key={tipo + id} position={coord} bounds="parent" {...this.dragHandlers}>
                            {iconoEstado}
                        </Draggable>
                    )
                }
            })
        }
    }
    handleSelectSuc(edif) {
        // this.setState({ edificios: [edif] });
        setTimeout(() => { this.updateDimensions() }, 100);
        let sensores = this.state.sensores
        let tipos = []

        for (let i in sensores) {
            if (sensores[i]["path"].split("/")[0] == edif) {
                tipos.push(sensores[i]["path"].split("/")[1])
            }
        }
        let sinRepetidos = tipos.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
        this.setState({ edificios: [edif], piso: sinRepetidos[0] })
    }

    backEdificios() {
        this.setState({ edificios: this.state.edifbuffer })
    }

    handleplanoFullScreen(){
        this.props.planoFullScreen()
        setTimeout(() => { this.updateDimensions() }, 100);
    }
    render() {
        return (<div className="plano">
            {this.state.sensores.length !== 0 ?
                this.state.edificios.length > 1 ?
                    <>
                        <h3>Seleccionar Ubicación</h3>
                        <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-evenly' }}>
                            {this.state.edificios.map((edif, h) => {
                                return <div key={edif + h} style={{ padding: "2rem", width: "15rem", margin: "1rem", color: "white", backgroundColor: "#5e93a7" }} className={" cursorPointer "} onClick={e => this.handleSelectSuc(edif)}>
                                    <h5>{edif}</h5>
                                    <h1><i className="fas fa-building"></i></h1>
                                </div>
                            })}
                        </div>
                    </>
                    :
                    <>
                        {this.SelectDinamic("path")}
                        <div style={{ position: "absolute", right: 0 }}>
                            <button type="button" className="btn-fill btn btn-grey btn-sm btn-plano" style={{ display: !this.state.dragHabilitado ? null : "none" }} onClick={() => this.setState({ dragHabilitado: !this.state.dragHabilitado })}><i className="fas fa-cog"></i></button>
                            <button type="button" className="btn-fill btn btn-success btn-sm btn-plano" style={{ display: this.state.dragHabilitado ? null : "none" }} onClick={() => this.guardoCoords()}><i className="fas fa-check"></i></button>
                            <button type="button" className="btn-fill btn btn-danger btn-sm btn-plano" style={{ display: this.state.dragHabilitado ? null : "none" }} onClick={() => this.resetMapa()}><i className="fas fa-times"></i></button>
                        </div>
                        <img src={"../" + localStorage.getItem("service") + "-" + this.state.edificios + "-" + this.state.piso + ".png"} 
                        onError={(e) => {e.target.src = "../default.png";}} 
                        style={{ width: "100%", height: "100%" }} alt="..." 
                        className="img-fluid" ref={this.imageRef} />
                        <span className="planoActiveButton" onClick={()=>this.handleplanoFullScreen()}>
                        <i className="fas fa-lg fa-expand cursorPointer"></i>
                        </span>
                        {this.amarIconoSensores()}
                        {this.state.edifbuffer.length > 1 ?
                            <div  className="btn-fill btn-danger cursorPointer salirButton" onClick={() => this.backEdificios()}>Salir</div>
                            : null
                        }
                    </>
                : null}
        </div>
        )

        // const pisosDinamic = this.SelectDinamic("path")
        // const iconos = this.amarIconoSensores()
        // return (

        //     <div className="plano">
        //         {pisosDinamic}
        //         <div style={{ position: "absolute", right: 0 }}>
        //             <button type="button" className="btn-fill btn btn-grey btn-sm btn-plano" style={{ display: !this.state.dragHabilitado ? null : "none" }} onClick={() => this.setState({ dragHabilitado: !this.state.dragHabilitado })}><i className="fas fa-cog"></i></button>
        //             <button type="button" className="btn-fill btn btn-success btn-sm btn-plano" style={{ display: this.state.dragHabilitado ? null : "none" }} onClick={() => this.guardoCoords()}><i className="fas fa-check"></i></button>
        //             <button type="button" className="btn-fill btn btn-danger btn-sm btn-plano" style={{ display: this.state.dragHabilitado ? null : "none" }} onClick={() => this.resetMapa()}><i className="fas fa-times"></i></button>
        //         </div>
        //         <img src={"../" + localStorage.getItem("service") + "-" + this.state.piso.replace("/", '-') + ".png"} style={{ width: "100%", height: "100%" }} alt="..." className="img-fluid" ref={this.imageRef} />
        //         {iconos}
        //     </div>
        // );
    }
}
