import React, { useEffect, useState } from 'react'
import "./HistoricGraph.css"
import ReactApexChart from "react-apexcharts";
import LoadingImg from "./../../assets/img/loading.svg"
import { sensorHistoric } from "./../../Services/Services";
import { subDays, format } from "date-fns";

const colors= ['#34aadc', '#4ee948', '#ff474e', '#ffd323', '#ff7f16', '#d446e3', '#55dddd', '#3a3a3a', '#5856d6', '#babdbf']


export default function HistoricsGraph({ id, sensor }) {
    const [series, setSeries] = useState([])
    const [loading, setLoading] = useState(false)
    const [attrActivos, setAttrActivos] = useState([])
    const [dynamicYAxis, setDynamicYAxis] = useState([]);
    //DataPicker
    const [dateSelected, setDateSelected] = useState(0)
    const [range, setRange] = useState(
        [{
            startDate: subDays(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())), 0),
            endDate: new Date(),
            key: 'selection'
        }])

    var option = {
    
        yaxis: dynamicYAxis,
        
        colors: colors,
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 3,
    
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        },
    
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.1,
                opacityTo: 0.1,
                stops: [0, 95, 100]
            }
        },
    
        chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: []
              },
              export: {
                csv: {
                  filename: "Historico " + new Date().toDateString(),
                  columnDelimiter: ',',
                  headerCategory: 'Fecha',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return timestamp
                  }
                },
              },
              autoSelected: 'zoom' 
            },
        }
    }
  

    useEffect(() => {
        if (attrActivos.length > 0) {
            contarDatos(range[0].startDate, range[0].endDate, attrActivos)
        }

    }, [range, attrActivos])

    useEffect(() => {
        const yAxisConfigurations = generateDynamicYAxisConfigurations(series.length);
        setDynamicYAxis(yAxisConfigurations);
      }, [series]);

    const generateDynamicYAxisConfigurations = (count) => {
        const yAxisConfigurations = [];
        for (let i = 0; i < count; i++) {
            const configuration = {
              opposite: i % 2 === 0 ? false : true,
              axisTicks: { show: true },
              axisBorder: { show: true, color: colors[i % colors.length] },
              labels: { style: { color: colors[i % colors.length] } }
            };
        
            yAxisConfigurations.push(configuration);
          }
        return yAxisConfigurations;
      };

    function btnTiempoPredefinido(btn) {
        setDateSelected(btn)
        setRange([{ startDate: subDays(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), btn), endDate: new Date(), key: 'selection' }])
    }
    async function contarDatos(from, to, attr) {
        setLoading(true)
        setSeries([])
        try {
            const resp = await sensorHistoric(sensor.path, sensor._id, sensor._type, [attr], from, to);
            setSeries(resp);
            console.log(resp);
          } catch (error) {
            console.error(error);
          }
      
          setLoading(false);
// 
    }

    function handleSetAttrActive(attr) {

            // Verificar si el objeto ya existe en el array
            const objetoExistente = attrActivos.find(objeto => objeto === attr);
            const eliminarObjeto = (objetoEliminar) => {
                // Filtrar el array y excluir el objeto a eliminar
                const nuevoArray = attrActivos.filter(objeto => objeto !== objetoEliminar);

                // Actualizar el estado con el nuevo array
                if (nuevoArray.length===0) {
                    setSeries([])
                }
                setAttrActivos(nuevoArray);
            };

            if (!objetoExistente) {
                // Si el objeto no existe, agregarlo al estado
                setAttrActivos([...attrActivos, attr]);
            }
            if (objetoExistente) {
                eliminarObjeto(attr)
               
            }
    }


    return (
        <>
            <h5>{sensor && sensor.path} | {sensor && sensor._zone} | {sensor && sensor._name}</h5>
            <div className="attrsContainer">
                {sensor.historicsAttrs ?sensor.historicsAttrs.map((attr, i) => {
                    return <div key={i+"attr"} onClick={() => handleSetAttrActive(attr)} className={`attrButton ${attrActivos.includes(attr) ? "attrButtonActive" : null}`}>{attr}</div>
                }):<div className="p-1 bg-danger text-danger">NO SE ENCONTRARON ATRIBUTOS VALIDOS</div>}
            </div>
            <div className="mt-3 mb-3 text-center">
                <div className="btn-group" role="group">
                    <button type="button" className={`btn btn-info  ${dateSelected === 30 && "btnPredActive"}`} onClick={() => btnTiempoPredefinido(30)}>Mes</button>
                    <button type="button" className={`btn btn-info ${dateSelected === 6 && "btnPredActive"}`} onClick={() => btnTiempoPredefinido(6)}>Semana</button>
                    <button type="button" className={`btn btn-info ${dateSelected === 0 && "btnPredActive"}`} onClick={() => btnTiempoPredefinido(0)}>Dia</button>
                    {/* <button type="button" className={`btn btn-outline-info ${dateSelected === 100 ? "active" : null}`} onClick={() => { setShowingDatePicker(!showingDatePicker); setDateSelected(100) }}>Rango</button>
                        <ExportToExcel data={dataExcel} ></ExportToExcel> */}
                </div>
                {/* {datePicker()} */}
            </div>

            {loading && <img style={{ width: '10rem', position: "absolute", left: '40%', top: '35%', zIndex: "20", backgroundColor: "#0000004d", padding: "2rem", borderRadius: "20px 0px 20px 0" }} src={LoadingImg}></img>}
            <ReactApexChart options={option} series={series} type="area" height="300" width="100%" />
        </>
    )
}
