import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals.js";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/style.css";
import "./assets/css/pe-icon-7-stroke.css";


ReactDOM.render(<App />, document.getElementById('root'));


serviceWorkerRegistration.register();

reportWebVitals();
