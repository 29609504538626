import React, { Component } from 'react';
import Particles from 'react-particles-js';
import { pedirToken } from "../Services/Services"
import Logo from "assets/img/LogoBanner.png"
import { Modal, Button } from 'react-bootstrap';
import config from '../config'
export default class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            user: "",
            pass: "",
            error: "",
            cantPuntos: 100,
            serviceprev: []
        }

    }
    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    selectService(serv) {
        localStorage.setItem("token", this.state.serviceprev._token)
        localStorage.setItem("name", this.state.serviceprev._name)
        localStorage.setItem("service", serv)
        localStorage.setItem("user", this.state.serviceprev._mail)
        this.props.logIn()
        this.props.history.push('/admin/dispositivos')
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.IniciarSesion()
        }
    }
    IniciarSesion = async () => {
        const user = this.state.user
        const password = this.state.pass
        pedirToken(user, password)
            .then(
                response => {
                    console.log(response);
                    if (response._token == undefined || response._name == undefined || response._organization == undefined) {
                        this.setState({ error: "user o password incorrecto!!!" })
                    } else {
                        if (response._organization.length > 1) {
                            this.setState({ serviceprev: response })
                            this.handleShow()
                        } else if (response._organization.length === 1) {
                            localStorage.setItem("token", response._token)
                            localStorage.setItem("name", response._name)
                            localStorage.setItem("service", response._organization)
                            localStorage.setItem("user", user)
                            this.props.logIn()
                            this.props.history.push('/admin/dispositivos')
                        }else  this.setState({ error: "No tiene servicios asociados!!!" })
                    }
                }
            )
            .catch(error => this.setState({ error }))
    }

    render() {
        const ModalService = <Modal show={this.state.show} onHide={() => this.handleClose()} >
            <Modal.Header closeButton>
                <Modal.Title>Seleccione el servicio</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', justifyContent: "space-around" }}>
                {this.state.serviceprev._organization ? this.state.serviceprev._organization.map((organization, h) =>

                    <div key={organization + h} style={{ textAlign: 'center', padding: "2rem", minWidth: "15rem", margin: "1rem", color: "white", backgroundColor: "#faad14" }} className={" cursorPointer "} onClick={() => this.selectService(organization)}>
                        <h5>{organization}</h5>
                        <h1><i className="fas fa-hand-pointer"></i></h1>
                    </div>
                ) : null}
            </Modal.Body>
        </Modal>
        return (
            <div className="loginpage">
                <div className="inner-bg">
                    <div className="" style={{ width: "370px" }}>

                        <div className="row">
                            {/* <div className="col-sm-6 col-sm-offset-3 form-box"> */}
                            <div className=" form-box">
                                <div className="form-top">
                                    <div className="form-top-left">
                                        <img alt="logo" src={Logo} style={{ width: "100%" }}></img>
                                        <p>Ingrese sus datos</p>
                                    </div>
                                    <div className="form-top-right">
                                        <i className="fas fa-lock"></i>
                                    </div>
                                </div>
                                <div className="form-bottom">
                                    <div className="login-form">
                                        <div className="form-group">
                                            <label className="sr-only" forhtml="form-username">user</label>
                                            <input onKeyPress={this.handleKeyPress} type="email" value={this.state.user} onChange={e => this.setState({ user: e.target.value, error: "" })} name="form-username" placeholder="Username..." className="form-username form-control" id="form-username" />
                                        </div>
                                        <div className="form-group">
                                            <label className="sr-only" forhtml="form-password">Contraseña</label>
                                            <input onKeyPress={this.handleKeyPress} value={this.state.pass} onChange={e => this.setState({ pass: e.target.value, error: "" })} type="password" name="form-password" placeholder="Password..." className="form-password form-control" id="form-password" />
                                        </div>
                                        <div className="text-danger h5 bg-danger text-center">{this.state.error}</div>
                                        <button type="button" onClick={() => this.IniciarSesion(this.state.user, this.state.pass)} className="btnlogin">Login!</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* FONDO EFECTO DE PARTICULAS */}
                <Particles
                    height="100vh"
                    params={{
                        "particles": {
                            "number": {
                                "value": this.state.cantPuntos
                            },
                            "line_linked": {
                                color: '#fff',
                                shadow: {
                                    enable: false,
                                    color: "#fff"
                                }
                            },
                            "color": {
                                "value": "#fff"
                            },

                            "size": {
                                "value": 3,
                            },
                            "move": {
                                "enable": true,
                                "speed": 4,
                                "direction": "none",
                                "random": false,
                                "straight": false,
                                "out_mode": "out",
                                "bounce": false,
                                "attract": {
                                    "enable": true,
                                    "rotateX": 600,
                                    "rotateY": 1200
                                }
                            },
                        },
                        "interactivity": {
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "grab"
                                },
                                "onclick": {
                                    "enable": true,
                                    "mode": "repulse"
                                }
                            },
                            "modes": {
                                "bubble": {
                                    "size": 6,
                                    "distance": 10
                                },
                                "repulse": {
                                    "distance": 100,
                                    "duration": 0.1
                                }
                            },
                            "resize": true
                        },
                        "retina_detect": true
                    }} />
                {ModalService}
            </div>
        )
    }
}
