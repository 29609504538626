import React, { Component } from 'react'
import iro from "@jaames/iro";
import { Row, Col, Button, Modal } from "react-bootstrap";
import IroColorPicker from 'Utils/IroColorPicker';
import "./LedStrip.css"
export default class LedStrip extends Component {
    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
            selected: "",
            colorActive: "1",
            color1: "rgb(255, 255, 255)",
            color2: "rgb(254, 252, 255)",
            color3: "rgb(254, 252, 255)",
            loading: false
        };
    }

    handleShow() {
        this.setState({ show: true });
    }
    handleClose() {
        this.setState({ show: false });
    }

    handlePostSensor(id, action, selected) {
        this.setState({ loading: true });
        this.props.postSensor(id, action).then(
            (response) => {
                this.setState({ loading: false });
                console.log(response);
            }
            )
        this.setState({ selected: selected })
    }

    onColorChange = color => {
        if (this.state.colorActive === "1") {
            this.setState({color1 : color.rgbString});
            this.props.postSensor(this.props.sensor._id, "CHANGE_COLOR", `[[${Object.values(color.rgb)}],[],[]]`  )
        }
        if (this.state.colorActive === "2") {
            this.props.postSensor(this.props.sensor._id, "CHANGE_COLOR", `[[],[${Object.values(color.rgb)}],[]]`  )
            this.setState({color2 : color.rgbString});
        }
        if (this.state.colorActive === "3") {
            this.props.postSensor(this.props.sensor._id, "CHANGE_COLOR", `[[],[],[${Object.values(color.rgb)}]]`  )
            this.setState({color3 : color.rgbString});
        }
        // console.log(color.hexString);
    };

    render() {
        console.log(this.state.color1);
        return (
            <>
                <div className="card card-stats">
                    {this.props.editar}
                    <div style={{ padding: "4px 0px 0px 4px", position: "absolute", fontSize: "1.1rem" }}>
          {this.props.linkQuality && this.props.linkQuality >= 0 || this.props.status >= 0 ? (

            <i
              className={
                this.props.status === 0
                ? "fas fa-wifi text-muted"
                :this.props.linkQuality === 0 
                ? "fas fa-wifi text-muted"
                  : this.props.linkQuality < 11
                    ? "fas fa-wifi text-danger"
                    : this.props.linkQuality < 41
                      ? "fas fa-wifi text-warning"
                      : this.props.linkQuality
                        ? "fas fa-wifi text-success"
                        :this.props.status >0
                        ? "fas fa-wifi text-success"
                          : null}
            ></i>
          ) : null}
          {this.props.batteryPercentage >= 0 ? (
            <div >
              <i
                className={
                  this.props.batteryStatus == 2
                    ? "fas fa-plug text-muted"
                    : this.props.batteryPercentage === 0
                      ? "fas fa-battery-quarter text-muted"
                      : this.props.batteryPercentage < 11
                        ? "fas fa-battery-quarter text-danger"
                        : this.props.batteryPercentage < 51
                          ? "fas fa-battery-half text-warning"
                          : this.props.batteryPercentage < 76
                            ? "fas fa-battery-three-quarters text-success"
                            : this.props.batteryPercentage
                              ? "fas fa-battery-full text-success"
                              : null
                }
              ></i>
              <div className="text-muted"><small><muted>{this.props.batteryPercentage}%</muted></small></div>
            </div>
          ) : null}

        </div>
                    <div className="content">
                        <Row>
                            {this.props.sensor._status === 1 && this.props.sensor._status !== null ?

                                <Col xs={12} onClick={() => this.handleShow()}>
                                    <div className={this.props.sensor._state !== 0 ? "icon-big text-center ledTitle cursorPointer" : "icon-big text-center ledTitleInaccessible cursorPointer"}>
                                        LED
                                    </div>
                                </Col>
                                :
                                <Col xs={12} >
                                    <div className="icon-big  text-center  ">
                                        <i className=" pe-7s-close-circle" style={{ color: "#b7b7b7" }} />
                                    </div>
                                </Col>
                            }
                            <Col xs={12}>
                                <div className="numbers">{this.props.sensor._device_type + "-" + this.props.sensor._name}</div>
                            </Col>
                        </Row>
                        <div className="footer ">
                            <hr />
                            <div className="stats">
                                <i className="pe-7s-light"></i> {this.props.sensor.path + " - " + this.props.sensor._zone}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.show} onHide={this.handleClose}>
                        {this.state.loading?<div className="modal-loading"><i className="fas fa-spinner fa-spin"></i></div>:null}
                    <Modal.Body>
                        {this.props.sensor._status === 1 && this.props.sensor._status !== null ?
                            this.props.sensor._state !== null && this.props.sensor._state !== undefined ?

                                <>
                                    <div className="text-right">
                                        <div><i style={{ fontWeight: "bold", fontSize: "4.5rem"}} onClick={() => this.handlePostSensor(this.props.sensor._id, this.props.sensor._state !== 0 ? "TURN_OFF" : "TURN_ON")} className={`cursorPointer fas fa-power-off text-${this.props.sensor._state !== 0 ? "success" : "danger"}`}></i></div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <IroColorPicker
                                            width={200}
                                            borderWidth="1"
                                            borderColor="#fff"
                                            layout={[{ component: iro.ui.Wheel }]}
                                            // color={this.state.color}
                                            onColorChange={this.onColorChange}
                                        />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <span onClick={(e)=> this.setState({colorActive: "1"})} style={{backgroundColor: this.state.color1}} className={`btnColor ${this.state.colorActive==="1"? "activeColor": ""}`}>C1</span>
                                        <span onClick={(e)=> this.setState({colorActive: "2"})} style={{backgroundColor: this.state.color2}} className={`btnColor ${this.state.colorActive==="2"? "activeColor": ""}`}>C2</span>
                                        <span onClick={(e)=> this.setState({colorActive: "3"})} style={{backgroundColor: this.state.color3}} className={`btnColor ${this.state.colorActive==="3"? "activeColor": ""}`}>C3</span>
                                    </div>
                                </>
                                :
                                <div>error al traer informacion</div>
                            :
                            <div>DESCONECTADO</div>
                        }
                        <div className="d-flex p-2 btnBox">
                            <button id="ps1" className={this.state.selected == "ps1" ? "btnLed selected" : "btnLed"} type="button" onClick={(e) => this.handlePostSensor(this.props.sensor._id, "TURN_PS1", e.target.id)} >PRESET 1</button>
                            <button id="ps2" className={this.state.selected == "ps2" ? "btnLed selected" : "btnLed"} type="button" onClick={(e) => this.handlePostSensor(this.props.sensor._id, "TURN_PS2", e.target.id)}>PRESET 2</button>
                            <button id="ps3" className={this.state.selected == "ps3" ? "btnLed selected" : "btnLed"} type="button" onClick={(e) => this.handlePostSensor(this.props.sensor._id, "TURN_PS3", e.target.id)}>PRESET 3</button>
                            <button id="ps4" className={this.state.selected == "ps4" ? "btnLed selected" : "btnLed"} type="button" onClick={(e) => this.handlePostSensor(this.props.sensor._id, "TURN_PS4", e.target.id)}>PRESET 4</button>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-xs btn-light btn btn-default" onClick={this.handleClose}>
                            SALIR
                        </Button>
                        {/* <Button
                            className="btn-fill btn-success"
                            onClick={() => console.log("clickkkkk")
                            }
                        >
                            GUARDAR
                        </Button> */}
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}
