import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "./RemoteControl.css"
import { Modal } from 'react-bootstrap';
import { postSensor } from "../../Services/Services";
export class RemoteControl extends Component {
  ////boton segun funcion///
  subirVolumen = "fas fa-arrow-circle-up"
  bajarVolumen = "fas fa-arrow-circle-down"
  prender = "fas fa-power-off"
  constructor(props) {
    super(props)
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  armarBotones() {
    let subir = <i  className="subirVolumen btndefault fas fa-plus " style={{opacity:"0.3"}}></i>
    let bajar = <i  className="bajarVolumen btndefault fas fa-minus " style={{opacity:"0.3"}}></i>
    let prender = <i  className="prender  fas fa-power-off" style={{opacity:"0.3"}}></i>
    let siguiente = <i className="siguiente  btndefault fas fa-chevron-up" style={{opacity:"0.3"}}></i>
    let anterior = <i className="anterior btndefault fas fa-chevron-down" style={{opacity:"0.3"}}></i>
    let num1 = <i className="num1 numerobtn " style={{opacity:"0.3"}}>1</i>
    let num2 = <i className="num2 numerobtn  " style={{opacity:"0.3"}}>2</i>
    let num3 = <i className="num3 numerobtn  " style={{opacity:"0.3"}}>3</i>
    let num4 = <i className="num4 numerobtn  " style={{opacity:"0.3"}}>4</i>
    let num5 = <i className="num5 numerobtn  " style={{opacity:"0.3"}}>5</i>
    let num6 = <i className="num6 numerobtn  " style={{opacity:"0.3"}}>6</i>
    let num7 = <i className="num7 numerobtn  " style={{opacity:"0.3"}}>7</i>
    let num8 = <i className="num8 numerobtn  " style={{opacity:"0.3"}}>8</i>
    let num9 = <i className="num9 numerobtn  " style={{opacity:"0.3"}}>9</i>
    let num0 = <i className="num0 numerobtn  " style={{opacity:"0.3"}}>0</i>
    let cool = <div className="cool extrabtn" style={{visibility:"hidden"}}>Cool</div>
    let heat = <div className="heat extrabtn" style={{visibility:"hidden"}}>heat</div>
    let dry = <div className="dry extrabtn" style={{visibility:"hidden"}}>Dry</div>
    let vent = <div className="vent extrabtn" style={{visibility:"hidden"}}>Vent</div>
    let source = <div className="source extrabtn" style={{visibility:"hidden"}}>Source</div>
    let mute = <div className="mute extrabtn" style={{visibility:"hidden"}}>Mute</div>
    let back = <div className="back extrabtn" style={{visibility:"hidden"}}>Back</div>
    let menu = <div className="menu extrabtn" style={{visibility:"hidden"}}>Menu</div>
    
    for (let i = 0; i < this.props.valores.length; i++) {
      let description= this.props.valores[i].description
      if (this.props.valores[i].description === "prender") {prender = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description +" cursorPointer fas fa-power-off"}></i>}
      if (this.props.valores[i].description === "subirVolumen") {subir = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description + " btndefault cursorPointer fas fa-plus"}></i>}
      if (this.props.valores[i].description === "bajarVolumen") {bajar = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description +" btndefault cursorPointer fas fa-minus"}></i>}
      if (this.props.valores[i].description === "siguiente") {siguiente = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description +" btndefault cursorPointer fas fa-chevron-up"}></i>}
      if (this.props.valores[i].description === "anterior") {anterior = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description +" btndefault cursorPointer fas fa-chevron-down"}></i>}
      if (this.props.valores[i].description === "num1") {num1 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>1</i>}
      if (this.props.valores[i].description === "num2") {num2 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>2</i>}
      if (this.props.valores[i].description === "num3") {num3 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>3</i>}
      if (this.props.valores[i].description === "num4") {num4 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>4</i>}
      if (this.props.valores[i].description === "num5") {num5 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>5</i>}
      if (this.props.valores[i].description === "num6") {num6 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>6</i>}
      if (this.props.valores[i].description === "num7") {num7 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>7</i>}
      if (this.props.valores[i].description === "num8") {num8 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>8</i>}
      if (this.props.valores[i].description === "num9") {num9 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>9</i>}
      if (this.props.valores[i].description === "num0") {num0 = <i id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" numerobtn cursorPointer"}>0</i>}
      if (this.props.valores[i].description === "cool") {cool = <div id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" extrabtn cursorPointer"}>Cool</div>}
      if (this.props.valores[i].description === "heat") {heat = <div id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" extrabtn cursorPointer"}>heat</div>}
      if (this.props.valores[i].description === "dry") {dry = <div id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" extrabtn cursorPointer"}>dry</div>}
      if (this.props.valores[i].description === "vent") {vent = <div id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" extrabtn cursorPointer"}>vent</div>}
      if (this.props.valores[i].description === "source") {source = <div id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" extrabtn cursorPointer"}>source</div>}
      if (this.props.valores[i].description === "mute") {mute = <div id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" extrabtn cursorPointer"}>mute</div>}
      if (this.props.valores[i].description === "back") {back = <div id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" extrabtn cursorPointer"}>back</div>}
      if (this.props.valores[i].description === "menu") {menu = <div id={this.props.id} onClick={e => postSensor(e.target.id, "SEND_MESSAGE", description)} className={description+" extrabtn cursorPointer"}>menu</div>}
    }
    return (
      <div className="remoteControl">
        {prender}{siguiente}{anterior}{subir}{bajar}
        {num0}{num1}{num2}{num3}{num4}{num5}{num6}{num7}{num8}{num9}
        {cool}{heat}{dry}{vent}
        {source}{mute}{back}{menu}
      </div>
    )
   
  }
  render() {
    const body = this.armarBotones()
    return (
      <div className="card card-stats">
        {/* {this.props.editar} */}
        <div className="content">
          <Row>
            <Col xs={12}>
              <div className="icon-big text-center icon-warning">
                <i id={this.props.valores.key} onClick={() => this.handleShow()} className=" pe-7s-keypad  text-grey cursorPointer"></i>
              </div>
            </Col>
            <Col xs={12}>
              <div className="numbers">
                <p>{this.props.statsText}</p>
                {this.props.statsValue}
              </div>
            </Col>
          </Row>
          <div className="footer">
            <hr />
            <div className="stats">
              {this.props.statsIcon} {this.props.statsIconText}
            </div>
          </div>
        </div>

        <Modal show={this.state.show} onHide={this.handleClose} >
          <Modal.Body>
            {body}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default RemoteControl;
