import config from '../config';

const parseAccion = (arbol, dispositivos, escenarios) => {
    let eventos = parseNodoAccion(arbol, dispositivos, escenarios);
    return eventos;
}

const parseNodoAccion = (nodo, dispositivos, escenarios) => {
    let hijos = []
    if (nodo.type === "group") {
        for (let idHijo in nodo.children1) {
            let hijo = nodo.children1[idHijo]
            if (hijo.type === "group" || hijo.type === "rule") {
                let acciones = parseNodoAccion(hijo, dispositivos, escenarios)
                hijos.push(...acciones)
            }
        }
    } else if (nodo.type === "rule") {
        let evento = {}
        const nombreEnPartes = nodo.properties.field.split(" ")
        let attr = (nodo.properties.field.split(".")[2]);
        let nombreDispositivo = nombreEnPartes.slice(1, nombreEnPartes.length).join(' ')
        let ids = buscarDispositivo([...dispositivos, ...escenarios], nombreDispositivo)
        if (ids.length === 0)
            throw new Error("Dispositivo o escenario no encontrado")
        else if (ids.length > 1)
            throw new Error("Nombre ambiguo")

        let id = ids[0].id;

        switch (nodo.properties.operator) { //turn_on, turn_off, pulse_on, pulse_off
            case "turn_ps1":
                evento.type = "TurnPS1"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_ps2":
                evento.type = "TurnPS2"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_ps3":
                evento.type = "TurnPS3"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_ps4":
                evento.type = "TurnPS4"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_open":
                evento.type = "OpenDevice"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_close":
                evento.type = "CloseDevice"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_stop":
                evento.type = "StopDevice"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_toggle":
                evento.type = "ToggleDevice"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_toggle_multi_device":
                evento.type = "ToggleMultiDevice"
                evento.params = {
                    attr: attr,
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_on":
                evento.type = "TurnDeviceOn"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_on_multi_device":
                evento.type = "TurnMultiDeviceOn"
                evento.params = {
                    attr: attr,
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_off":
                evento.type = "TurnDeviceOff"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "turn_off_multi_device":
                evento.type = "TurnMultiDeviceOff"
                evento.params = {
                    attr: attr,
                    deviceID: id,
                    service: localStorage.getItem("service"),
                }
                break;
            case "pulse_on":
                evento.type = "PulseDevice"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                    pulseType: "ON",
                    interval: nodo.properties.value[0] * 1000
                }
                break;
            case "pulse_on_multi_device":
                evento.type = "PulseMultiDevice"
                evento.params = {
                    attr: attr,
                    deviceID: id,
                    service: localStorage.getItem("service"),
                    pulseType: "ON",
                    interval: nodo.properties.value[0] * 1000
                }
                break;
            case "pulse_off":
                evento.type = "PulseDevice"
                evento.params = {
                    deviceID: id,
                    service: localStorage.getItem("service"),
                    pulseType: "OFF",
                    interval: nodo.properties.value[0] * 1000
                }
                break;
            case "pulse_off_multi_device":
                evento.type = "PulseMultiDevice"
                evento.params = {
                    attr: attr,
                    deviceID: id,
                    service: localStorage.getItem("service"),
                    pulseType: "OFF",
                    interval: nodo.properties.value[0] * 1000
                }
                break;
            case "run":
                evento.type = "RunScene"
                evento.params = {
                    sceneId: id,
                    service: localStorage.getItem("service"),
                }
        }
        hijos.push(evento)
    }
    return hijos
}


/**
 * @author Juan Andrés Mezzera
 * @method parseRegla
 * @description Crea, a partir de una regla generada por el paquete utilizado para 
 *              diagramar reglas, una regla según el formato aceptado por el BE.
 * @param {Object} cruda regla a ser parseada
 * @returns regla parseada
 */
const parseRegla = (cruda, dispositivos) => {
    let parseada = {}
    parseada.conditions = manejarNodo(cruda, dispositivos);

    return parseada;
}

/**
 * @author Juan Andrés Mezzera
 * @method manejarNodo
 * @description Método auxiliar utilizado para -recursivamente- manejar cada uno
 *              de los nodos del árbol.
 * @param {Object} nodo Nodo a ser procesado
 * @returns representación del nodo en el formato deseado
 */
const manejarNodo = (nodo, dispositivos) => {
    //TODO: Manejar opción NOT
    let condicion = {}
    if (nodo.type === "group") {
        //Recorrer todos los hijos
        let hijos = [];
        for (let idHijo in nodo.children1) {
            let hijo = nodo.children1[idHijo]
            if (hijo.type === "group" || hijo.type === "rule") {
                hijos.push(manejarNodo(hijo, dispositivos))
            }
        }
        if (!nodo.properties) {
            condicion = { "any": hijos }
        } else if (nodo.properties.conjunction === "AND") {
            condicion = { "all": hijos }
        } else if (nodo.properties.conjunction === "OR") {
            condicion = { "any": hijos }
        } else {
            throw new Error("Error al armar la regla")
        }
    } else if (nodo.type === "rule") {
        let campo = nodo.properties.field.split('.')
        let fact = campo[0]
        condicion.fact = fact
        condicion.operator = operadores[nodo.properties.operator]
        switch (fact) {
            case "deviceState":
                const nombreConTipo = campo[1].split(" ").slice(1).join(" ") //saca el primer campo separado por espacio

                let ids = buscarDispositivo(dispositivos, nombreConTipo)
                // let ids = dispositivos.filter(dispositivo => dispositivo.name === sacarTipoAlNombre());
                if (ids.length === 0)
                    throw new Error("Dispositivo no encontrado")
                else if (ids.length > 1)
                    throw new Error("Nombre ambiguo")

                let id = ids[0].id;

                condicion.params = {
                    service: localStorage.getItem("service"),
                    id,
                    attr: campo[2]
                }
                // condicion.fact = 'device'
                condicion.path = '$._' + campo[2]
                switch (nodo.properties.operator) {
                    case ("greater"): condicion.operator = "greaterThan"
                        break
                    case ("less"): condicion.operator = "lessThan"
                        break
                    default: condicion.operator = "equal"
                }
                if (campo[2] == "state") {
                    condicion.value = nodo.properties.value[0] ? 1 : 0
                } else if (campo[2] == "state1") {
                    condicion.value = nodo.properties.value[0] ? 1 : 0
                } else if (campo[2] == "state2") {
                    condicion.value = nodo.properties.value[0] ? 1 : 0
                } else {
                    condicion.value = nodo.properties.value[0]
                }
                // condicion.operator = nodo.properties.operator
                // condicion.operator = 'equal'
                // condicion.value = nodo.properties.value[0] ? 1 : 0

                // condicion.path = "$._" + campo[2]
                // condicion.value = nodo.properties.value[0] ? 1 : 0
                break;
            case "time":
                switch (campo[1]) {
                    case "day":
                        condicion.fact = 'day'
                        condicion.value = nodo.properties.value[0];
                        condicion.operator = 'in'
                        break;
                    case "time":
                        const [hours, minutes, _] = nodo.properties.value[0].split(':');
                        condicion.params = { hours, minutes };
                        condicion.value = true;
                        break;
                    case "timeSlot":
                        const offset = new Date().getTimezoneOffset() / 60;
                        condicion.fact = 'timeSlot'
                        const desde = nodo.properties.value[0]
                        const hasta = nodo.properties.value[1]
                        const [fromHoursString, fromMinutesString] = desde.split(':')
                        let [toHoursString, toMinutesString] = hasta.split(':')
                        let [fromHours, fromMinutes, toHours, toMinutes] = [fromHoursString, fromMinutesString, toHoursString, toMinutesString].map(v => parseInt(v));
                        toHours += offset;
                        fromHours += offset;
                        condicion.params = { fromHours, fromMinutes, toHours, toMinutes }
                        condicion.value = true
                        condicion.operator = 'equal'

                        break;

                    default:
                        throw new Error("No implementado")
                }
                break;

            default:
                throw new Error("Error al armar la regla: no se conoce el fact " + fact)
        }
    }
    return condicion
}

//Mapa para convertir operadores entre el módulo gráfico y los aceptados por 
//el BE
const operadores = { equal: "equal", multiselect_equals: "in" }


const traerAtributos = async (onlyChangeable) => {
    let urlEnding = onlyChangeable ? 'onlyChangeable' : 'all'
    const resp = await fetch(`${config.baseUrl}/rules/options/${urlEnding}`,
        {
            headers: {
                service: localStorage.getItem("service"),
                token: localStorage.getItem("token")
            }
        });
    return await resp.json();
}

const traerEscenarios = async () => {
    const resp = await fetch(`${config.baseUrl}/rules/options/scenarios`,
        {
            headers: {
                service: localStorage.getItem("service"),
                token: localStorage.getItem("token")
            }
        });
    return await resp.json();
}

const parseConfig = (atributos) => {
    let opciones = {}
    let indice = 0

    if (typeof atributos === 'object' && atributos !== null) {
        // Verificar si el objeto es iterable
        if (Object.keys(atributos).length > 0) {
            // Iterar sobre los elementos de atributos
            for (let atributo of atributos) {
                indice++
                let attrs = {}
                for (let attr in atributo.attrs) {
                    let estructura = crearAtributo(atributo.attrs[attr], attr)
                    if (estructura)
                        attrs[attr] = estructura
                }

                const finalEtiqueta = atributo.type ? ` (${atributo.type})` : ""

                opciones[indice + ": " + atributo.name + finalEtiqueta] = {
                    label: indice + ": " + atributo.path + " | " + atributo.name + finalEtiqueta,
                    type: '!struct',
                    subfields: attrs,
                }
            }
            return opciones
        } else {
            console.log('atributos no es iterable');
        }
    } else {
        console.log('atributos no es un objeto');
    }

}
const crearAtributo = (atributo, nombre) => {
    let estructura;
    switch (nombre) {
        case "batteryLevel":
            estructura = {
                label: "Bateria",
                type: "number",
                fieldSettings: {
                    min: 0,
                },
                operators: ['less', 'greater', 'equal'],
                valueSources: ['value'],
                preferWidgets: ['number'],
            }
            break;
        case "batteryPercentage":
            estructura = {
                label: "Bateria",
                type: "number",
                fieldSettings: {
                    min: 0,
                },
                operators: ['less', 'greater', 'equal'],
                valueSources: ['value'],
                preferWidgets: ['number'],
            }
            break;
        case "humidity":
            estructura = {
                label: "humedad",
                type: "number",
                fieldSettings: {
                    min: 0,
                },
                operators: ['less', 'greater', 'equal'],
                valueSources: ['value'],
                preferWidgets: ['number'],
            }
            break;
        case "temperature":
            estructura = {
                label: "temperatura",
                type: "number",
                fieldSettings: {
                    min: 0,
                },
                operators: ['less', 'greater', 'equal'],
                valueSources: ['value'],
                preferWidgets: ['number'],
            }
            break;
        case "power":
            estructura = {
                label: "potencia",
                type: "number",
                fieldSettings: {
                    min: 0,
                },
                operators: ['less', 'greater', 'equal'],
                valueSources: ['value'],
                preferWidgets: ['number'],
            }
            break;
        case "state":
            estructura = {
                name: 'estado',
                label: 'Estado',
                type: 'boolean',
                operators: ['equal'],
                fieldSettings: {
                    labelYes: '',
                    labelNo: ''
                }
            }
            break;
        case "state1":
            estructura = {
                name: 'estado',
                label: 'Estado1',
                type: 'boolean',
                operators: ['equal'],
                fieldSettings: {
                    labelYes: '',
                    labelNo: ''
                }
            }
            break;
        case "state2":
            estructura = {
                name: 'estado',
                label: 'Estado2',
                type: 'boolean',
                operators: ['equal'],
                fieldSettings: {
                    labelYes: '',
                    labelNo: ''
                }
            }
            break;
        // case "temperature":
        //     estructura = {
        //         label: 'Temperatura',
        //         type: 'number',
        //         valueSources: 'temperature',
        //         fieldSettings: {
        //             min: 0,
        //             max: 50
        //         },
        //         defautlValue: 20
        //     }
        //     break;
    }
    return estructura
}
const crearActionAtributo = (atributo, nombre, type) => {
    console.log(nombre);

    let estructura;
    switch (nombre) {
        case "state":
            if (type === "CORTINA") {
                estructura = {
                    name: 'estado',
                    label: 'Estado',
                    type: 'number',
                    operators: ['turn_open', 'turn_close', 'turn_stop'],
                    fieldSettings: {
                        labelYes: '',
                        labelNo: ''
                    }
                }
            } else if (type === "LED") {
                estructura = {
                    name: 'estado',
                    label: 'Estado',
                    type: 'number',
                    operators: ['turn_on', 'turn_off', 'turn_ps1', 'turn_ps2', 'turn_ps3', 'turn_ps4'],
                    fieldSettings: {
                        labelYes: '',
                        labelNo: ''
                    }
                }
            } else {
                estructura = {
                    name: 'estado',
                    label: 'Estado',
                    type: 'number',
                    operators: ['turn_on', 'turn_off', 'pulse_on', 'pulse_off', 'turn_toggle'],
                    fieldSettings: {
                        labelYes: '',
                        labelNo: ''
                    }
                }
            }
            break;
        case "state1":
            estructura = {
                name: 'estado',
                label: 'Switch 1',
                type: 'number',
                operators: ['turn_on_multi_device', 'turn_off_multi_device', 'pulse_on_multi_device', 'pulse_off_multi_device', 'turn_toggle_multi_device'],
            }
            break;
        case "state2":
            estructura = {
                name: 'estado',
                label: 'Switch 2',
                type: 'number',
                operators: ['turn_on_multi_device', 'turn_off_multi_device', 'pulse_on_multi_device', 'pulse_off_multi_device', 'turn_toggle_multi_device'],
                fieldSettings: {
                    labelYes: '',
                    labelNo: ''
                }
            }
            break;

    }
    return estructura
}
const parseConfigActions = (atributos) => {
    let opciones = {}
    let indice = 0
    // for (let atributo of atributos){
    //     indice++
    //     const finalEtiqueta = atributo.type ? ` (${atributo.type})` : ""
    //     campo[indice + ": " + atributo.name + finalEtiqueta] = {
    //         label: indice + ": " + atributo.name  + finalEtiqueta,
    //         type: 'number',
    //         operators: ['turn_on', 'turn_off', 'pulse_on', 'pulse_off', 'turn_toggle'],
    //         //operators: ['turn_on', 'turn_off']
    //     }
    // }
    if (typeof atributos === 'object' && atributos !== null) {
        // Verificar si el objeto es iterable
        if (Object.keys(atributos).length > 0) {
            // Iterar sobre los elementos de atributos
            for (let atributo of atributos) {
                indice++
                let attrs = {}
                for (let attr in atributo.attrs) {
                    let estructura = crearActionAtributo(atributo.attrs[attr], attr, atributo.type)
                    if (estructura)
                        attrs[attr] = estructura
                }

                const finalEtiqueta = atributo.type ? ` (${atributo.type})` : ""

                opciones[indice + ": " + atributo.name + finalEtiqueta] = {
                    label: indice + ": " + atributo.path + " | " + atributo.name + finalEtiqueta,
                    type: '!struct',
                    subfields: attrs,
                }
            }
            return opciones
        }
    }
}

const parseConfigActionsScenarios = (escenarios) => {
    let campo = {}
    let indice = 0
    for (let escenario of escenarios) {
        indice++
        campo[indice + ": " + escenario.name + " "] = { //Se necesita el epacio al final, ya que luego se separara el campo segun espacios
            label: indice + ": " + escenario.name,
            // type: 'nubmer',
            type: 'number',
            //operators: ['turn_on', 'turn_off', 'pulse_on', 'pulse_off'],
            operators: ['run']
        }
    }
    return campo
}

const mandarRegla = async regla => {
    const resp = await fetch(`${config.baseUrl}/rules/`, {
        headers: {
            'content-type': 'application/json',
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        },
        method: "POST",
        body: JSON.stringify(regla)
    });
    return await resp.json();
}

const eliminarRegla = async regla => {

    const resp = await fetch(`${config.baseUrl}/rules/${regla._id}`, {
        headers: {
            //'content-type': 'application/json',
            service: localStorage.getItem("service"),
            token: localStorage.getItem("token")
        },
        method: "DELETE"
    });
    return await resp.json();
}

const traerReglas = async () => {
    const resp = await fetch(`${config.baseUrl}/rules`,
        {
            headers: {
                service: localStorage.getItem("service"),
                token: localStorage.getItem("token")
            }
        });
    return await resp.json();
}

/**
 * 
 * @param {string} nombre - nombre de la forma nombre*espacio*(tipo)
 * @returns {{tipo: string, nombre: string}} 
 */
const sacarTipoAlNombre = (nombre) => {

    const nombreEnPartes = nombre.split('(')
    const nombreConEspacioAlFinal = nombreEnPartes[0]
    const nombreSinEspacioAlFinal = nombreConEspacioAlFinal.slice(0, nombreConEspacioAlFinal.length - 1); //devolver tambien el tipo
    const tipo = nombreEnPartes[1] && nombreEnPartes[1].split(')')[0]

    return { nombre: nombreSinEspacioAlFinal, tipo };
}

const buscarDispositivo = (dispositivos, nombreConTipo) => {
    return dispositivos.filter(dispositivo => {
        const { nombre, tipo } = sacarTipoAlNombre(nombreConTipo);
        if (tipo === undefined) {
            return dispositivo.name === nombre && dispositivo.type === undefined
        }
        return dispositivo.name === nombre && dispositivo.type === tipo
    })
}

const initTree = {
    "type": "group",
    "id": "9a99988a-0123-4456-b89a-b1607f326fd8",
    "children1": {
        "a98ab9b9-cdef-4012-b456-71607f326fd9": {
            "type": "rule",
            "properties": {
                "field": null,
                "operator": null,
                "value": [],
                "valueSrc": []

            },
        }
    },
    "properties": {
        "conjunction": "AND",
        "not": false
    }
}
export { parseRegla, traerAtributos, traerEscenarios, parseConfig, parseConfigActions, parseConfigActionsScenarios, parseAccion, initTree, mandarRegla, traerReglas, eliminarRegla }