import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "./WeatherStation.css";

export class WeatherStation extends Component {
  render() {
    const compassRotation = {
      transform: `rotate(${this.props.sensor._wind_direction}deg)`,
    };
    return (
      <div className="card card-stats">
        {this.props.editar}
        <div style={{ padding: "4px 0px 0px 4px", position: "absolute", fontSize: "1.1rem" }}>
        </div>
        <div className="content">
          <Row>
            <div className="containerStation">

              <div className="compass">
                <div className="direction-labels">
                  <div className="direction-label">S</div>
                  <div className="direction-label">W</div>
                  <div className="direction-label">N</div>
                  <div className="direction-label">E</div>
                </div>
                <div className="needle" style={compassRotation}></div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent:"center" }}>
                <span ><i className="fas fa-thermometer-half text-success"></i>{" " + this.props.sensor._temperature.toFixed(0) + "°C"}</span>
                <span ><i className="fab fa-empire text-info"></i>{" " + this.props.sensor._relative_humidity.toFixed(0) + "%"}</span>
                <span><i className="fas fa-wind text-primary"></i>{" " + this.props.sensor._wind_speed.toFixed(0) + ""}</span>
                {/* Temp: {this.props.sensor._temperature}
              Hum: {this.props.sensor._relative_humidity}
              Velocidad Viento: {this.props.sensor._wind_speed}
              Lluvia: {this.props.sensor._rain} */}

              </div>
            </div>
            <Col xs={12}>
              <div className="numbers">{this.props.statsValue}</div>
            </Col>
          </Row>
          <div className="footer ">
            <hr />
            <div className="stats">
              {this.props.statsIcon} {this.props.statsIconText}
              {this.props.masInfo}
            </div>
          </div>
          {this.props.historics ? this.props.historics : <div />}
        </div>
      </div>
    );
  }
}

export default WeatherStation;
