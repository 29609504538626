import React from 'react'

const Regla = props => {
  return (
    <tr>
      <td>{props.regla.name}</td>
      <td>
        <button type="button" className="ant-btn ant-btn-danger ant-btn-sm ant-btn-icon-only" onClick={e=>props.SetDelete(props.regla)}>
          <i className="fas fa-trash-alt"></i>
        </button>
        <button type="button" className="ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only" style={{marginLeft: "0.4rem"}} onClick={() => props.mostrarRegla(props.regla)}>
          <i className="fas fa-cog"></i>
        </button>
      </td>
    </tr>
  )
}

export default Regla;