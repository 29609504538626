import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import "moment/locale/es"

export class WeatherCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      prediccion: ""
    }
  }

  kalvinToCelcius(kalvin) {
    let valor = kalvin - 273.15
    return valor.toFixed(0)
  }

  transformForecast(data) {
    let filtro = data.list.filter(item => (

      // moment.unix(item.dt).utc().hour() === 6||
      moment.unix(item.dt).utc().hour() === 12 &&
      moment.unix(item.dt).format("dddd") !== moment(new Date()).format("dddd")
      // moment.unix(item.dt).utc().hour() === 18
    ))

    const tresDias = [filtro[0], filtro[1], filtro[2]]

    let armadoDiario = tresDias.map((dia,h) => {
      return(
      <Col key={h + "dia"}xs={4}>
        <small>
          <div>{moment.unix(dia.dt).format("dddd")}
          </div>
          <div><img alt="iweather"className="icon-small" src={"http://openweathermap.org/img/wn/" + dia.weather[0].icon + "@2x.png"} /></div>
          {"max:" + this.kalvinToCelcius(dia.main.temp_max)} <i className='fas fa-long-arrow-alt-up text-success'></i>{" "}
          {"min:" + this.kalvinToCelcius(dia.main.temp_min)} <i className='fas fa-long-arrow-alt-down text-danger'></i>
        </small>
      </Col>)
    })
    this.setState({ prediccion: armadoDiario })
  }


  componentDidMount() {
    this.transformForecast(this.props.prediccionClima)

  }
  render() {
    console.log(this.props.climaAhora)
    console.log(this.props.prediccionClima)
    return (
      <div className="card card-stats">
        <div className="content text-center">
          <Row>
            <span className="h5">{moment.unix(this.props.climaAhora.dt).format("dddd")}</span>
            <Col xs={12}>
              <div className="icon-big text-center icon-warning">
                <img alt="iclima"src={"http://openweathermap.org/img/wn/" + this.props.climaAhora.weather[0].icon + "@2x.png"} />
              </div>
            </Col>
            <Col xs={12}>
              {this.props.climaAhora.name}
              <div className="numbers">
                {this.kalvinToCelcius(this.props.climaAhora.main.temp) + "°C | " + this.props.climaAhora.main.humidity + "%"}
              </div>
              <small>
                {"max:" + this.kalvinToCelcius(this.props.climaAhora.main.temp_max)} <i className='fas fa-long-arrow-alt-up text-success'></i>{" "}
                {"min:" + this.kalvinToCelcius(this.props.climaAhora.main.temp_min)} <i className='fas fa-long-arrow-alt-down text-danger'></i>
              </small>
            </Col>
          </Row>
          {/* <Row>
            <hr></hr>
            {this.state.prediccion}
          </Row> */}
        </div>
      </div>
    );
  }
}

export default WeatherCard;
