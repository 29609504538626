const env = process.env.REACT_APP_ENVIRONMENT || "dev";

let config = {
    baseUrl: "https://dev.acflex.com.uy",
    socketUrl: "https://dev.acflex.com.uy"
}

if (env === "prod"){
    config = {
        baseUrl: "https://www.acflex.com.uy",
        socketUrl: "https://www.acflex.com.uy"
    }
}

if (env === "ggil"){
    config = {
        baseUrl: "http://192.168.87.11:8080",
        socketUrl: "http://192.168.87.11:8080"
    }
}

if (env === "localhost"){
    config = {
        baseUrl: "http://localhost:8080",
        socketUrl: "http://localhost:8080"
    }
}

export default config