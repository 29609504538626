// @flow
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { traerSensores, postSensor } from "../Services/Services";
import Arbol from 'components/ArbolReglas/Arbol';
import config from '../config';
import { parseAccion, parseConfigActions, parseConfigActionsScenarios, traerAtributos, traerEscenarios as traerNombreEscenarios } from '../Services/Reglas';
import { conditionsConfig, actionsConfig } from 'components/ArbolReglas/Configs';
import { Query, Builder, BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder';
import { initTree } from '../Services/Reglas';
import { traerEscenarios, mandarEscenario, eliminarEscenario, ejecutarEscenario } from '../Services/Escenarios';
import { Button, Modal } from "react-bootstrap";

class SceneList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedId: null,
            sensores: [],
            scenes: [],
            actionsConfig: actionsConfig,
            arbolAccionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(initTree), actionsConfig),
            edit: false,
            newName: "",
            newURL: "",
            newDescription: "",
            escenarios: [],
            nombreEscenarios: [],
            selectedScene: null,
            modalShow: false,
            modalBody: "Info",
            selectedSceneToDelete: null,
            modalTitle: "",
            modalType: "",
            modalIcono: false,

        }
    }

    async componentDidMount() {
        await traerSensores().then(
            sensores => {
                this.setState({ sensores });
            })

        Promise.all([traerAtributos(false), traerAtributos(true), traerNombreEscenarios()])
            .then(([todos, soloModificables, escenarios]) => {
                actionsConfig.fields.deviceState.subfields = parseConfigActions(soloModificables)
                // actionsConfig.fields.scenarios.subfields = parseConfigActionsScenarios(escenarios)
                if (escenarios.length==0) {
                    delete actionsConfig.fields.scenarios
                  }else {
          
                    actionsConfig.fields.scenarios.subfields = parseConfigActionsScenarios(escenarios)
                  }
                this.setState({ conditionsConfig, actionsConfig, dispositivos: todos, nombreEscenarios: escenarios })
            })

        await traerEscenarios()
            .then(scenes => this.setState({ scenes }))
    }

    handleClose = () => {
        this.setState({
            modalShow: false,
            modalIcono: false,
            modalTitle: "",
            modalBody: "",
        });
    }


    cancelarEditar = () => {
        this.setState({
            edit: false,
            newName: "",
            newURL: "",
            newDescription: "",
            selectedId: null,
            selectedScene: null,
            arbolAccionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(initTree), actionsConfig),
            hovered: null,
        })
    }

    limpiarEditar = () => {
        this.setState({
            edit: true,
            newName: "",
            newURL: "",
            newDescription: "",
            selectedId: null,
            selectedScene: null,
            arbolAccionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(initTree), actionsConfig),
            modalBody: "",
            modalTitle: "",
            selectedSceneToDelete: null,
            modalShow: false,
        })
    }

    setEliminarEscenaSeleccionada = (scene) => {
        if (scene) {
            this.setState({
                modalShow: true,
                modalTitle: "Eliminar escenario",
                modalBody: "Está seguro que desea eliminar el escenario seleccionado?",
                selectedSceneToDelete: scene,
                modalType: "dialog",

            })
        }
    }

    setError = () => {

        this.setState({
            modalShow: true,
            modalTitle: "Escenarios",
            modalBody: "Se ha detectado un error al definir la acción",
            modalType: "alert",

        })

    }

    aceptarModal = () => {
        if (this.state.selectedSceneToDelete) {


            eliminarEscenario(this.state.selectedSceneToDelete)
                .then((x) => {
                    this.limpiarEditar()
                    traerEscenarios()
                        .then(scenes => this.setState({ scenes }))
                })
                .catch((e) => {
                    this.limpiarEditar()
                })
        }
    }

    actionChanged = (immutableTree, config) => {
        this.setState({ actionTree: immutableTree, actionsConfig: config });
    }

    guardarEscenario = (e) => {
        try {
            let actionTree = QbUtils.getTree(this.state.actionTree, this.state.actionsConfig)
            let escenario = {}
            escenario.selectedId = this.state.selectedId
            escenario.events = parseAccion(actionTree, this.state.dispositivos, this.state.nombreEscenarios)
            escenario.name = this.state.newName
            escenario.imageUrl = this.state.newURL
            escenario.description = this.state.newDescription
            var d = new Date();
            let fecha = d.getFullYear() + "-" + (d.getMonth() + 1).toString() + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

            if (this.state.newName == "") {
                escenario.name = fecha
            }

            if (this.state.newURL == "") {
                escenario.imageUrl = config.baseUrl + "/escenarios/puntero.png" 
               
            }

            if (this.state.newDescription == "" && this.state.newName == "") {
                escenario.description = fecha
            }
            else {
                if (this.state.newDescription == "")
                    escenario.description = this.state.newName
            }
            escenario.rawScene = actionTree
            mandarEscenario(escenario)
                .then((resp) => {

                    if (resp.status === 500) {
                        this.setError()
                    }
                    else {
                        this.cancelarEditar();
                        traerEscenarios()
                            .then(scenes => this.setState({ scenes }))
                    }
                })
        }
        catch (e) {
            //alert("Complete todos los campos necesarios");
            this.setError()
        }
    }

    showButtons = () => {

        return (
            <Row>
                <Botones onClick={this.guardarEscenario} type="button" className="btn-fill btn btn-success" title="Guardar"><i className="fas fa-check"></i> </Botones>
                <Botones onClick={this.cancelarEditar} type="button" className="btn-fill btn btn-info " title="Cancelar"><i className="fas fa-window-close"></i> </Botones>
                {/*                 <Botones onClick={this.setEliminarEscenaSeleccionada} type="button" disabled={!(this.state.selectedId)} className="btn-fill btn btn-danger " title="Eliminar"><i className="fas fa-trash"></i></Botones> */}
            </Row>
        )
    }

    modalDisplay = () => {

        if (this.state.modalType === "dialog") {
            return (
                <div>
                    <Button onClick={this.aceptarModal} className="btn-fill btn btn-danger" >
                        ELIMINAR
                    </Button>
                    <Button onClick={this.handleClose} variant="primary">
                        CANCELAR
                    </Button>
                </div>
            )
        }
        else {
            return (<Button onClick={this.handleClose} className="btn-fill btn btn-info" >
                ACEPTAR
            </Button>)


        }

    }

    /*    completeSelectedSceneToEditw = (scene) => {
           if (this.state.edit) {
               //console.log(scene)
               this.setState({
                   selectedId: scene._id,
                   new: false,
                   newName: scene._name,
                   newURL: scene._imageUrl,
                   newDescription: scene._description,
                   selectedScene: scene,
                   arbolAccionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(scene._rawScene), this.state.actionsConfig),
                   hovered: null,
               })
           }
       }
    */
    getSelectedScene = (scene) => {
        if (this.state.edit) {
            this.setState({
                selectedId: scene._id,
                new: false,
                newName: scene._name,
                newURL: scene._imageUrl,
                newDescription: scene._description,
                selectedScene: scene,
                arbolAccionesReglaSeleccionada: QbUtils.checkTree(QbUtils.loadTree(scene._rawScene), this.state.actionsConfig),
            })
        }
        else {
            ejecutarEscenario(scene)
                .then((e) => {
                    console.log("ejecutado con status: ", e)
                })
                .catch((e) => { console.log("error:", e) })
        }
    }

    guardarIconoSelect(icono){
        this.setState({newURL:icono})
        this.handleClose()
    }
    render() {
        console.log(this.state.selectedId);
        const renderTree = (this.state.edit) ?
            (
                <Arbol id="accion" tree={this.state.arbolAccionesReglaSeleccionada} title="ACCIÓN" config={this.state.actionsConfig} onChange={this.actionChanged} />
            )
            :
            (
                <div />
            )

        const newScene = (this.state.edit)
            ?
            (
                <DivEscena>
                    <Row>
                        <Label>Nombre de la escena</Label>
                        <InputEscena
                            onChange={(e) => { this.setState({ newName: e.target.value }) }}
                            disabled={this.state.reglaSeleccionada}
                            placeholder="Nombre de la escena"
                            type="text"
                            value={this.state.newName}
                            className="form-control"
                            style={{ width: "auto", display: "inline-block", verticalAlign: "middle" }}>
                        </InputEscena>
                    </Row>
                    <Row>
                        <Label>Icono</Label>
                        <div style={{ width: "100%" }}>
                            <ImageIcono src={this.state.newURL ? this.state.newURL : config.baseUrl + "/escenarios/puntero.png"}></ImageIcono>
                            <BotonesIconos onClick={() => this.setState({ modalIcono: true })} type="button" className=" btn " title="Cambiar">Cambiar</BotonesIconos>

                        </div>
                    </Row>
                    <Row>
                        <Label>Descripción</Label>
                        <InputEscena
                            onChange={(e) => { this.setState({ newDescription: e.target.value }) }}
                            value={this.state.newDescription}
                            disabled={this.state.reglaSeleccionada}
                            placeholder="Descripción"
                            type="text"
                            className="form-control"
                            style={{ width: "auto", display: "inline-block", verticalAlign: "middle" }}>
                        </InputEscena>
                    </Row>

                    {this.showButtons()}
                </DivEscena>
            )
            :
            (
                <div />
            )

        return (
            <Container>


                <Modal show={this.state.modalIcono} onHide={this.handleClose} >
                    <Modal.Header>
                        <Modal.Title> Selecciona un Icono </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <ImageIcono className="cursorPointer" onClick={e=>this.guardarIconoSelect(e.target.src)} src={config.baseUrl + "/escenarios/puntero.png"}></ImageIcono>
                    <ImageIcono className="cursorPointer" onClick={e=>this.guardarIconoSelect(e.target.src)} src={config.baseUrl + "/escenarios/salir.png"}></ImageIcono>
                    <ImageIcono className="cursorPointer" onClick={e=>this.guardarIconoSelect(e.target.src)} src={config.baseUrl + "/escenarios/entrar.png"}></ImageIcono>
                    <ImageIcono className="cursorPointer" onClick={e=>this.guardarIconoSelect(e.target.src)} src={config.baseUrl + "/escenarios/dia.png"}></ImageIcono>
                    <ImageIcono className="cursorPointer" onClick={e=>this.guardarIconoSelect(e.target.src)} src={config.baseUrl + "/escenarios/noche.png"}></ImageIcono>
                    <ImageIcono className="cursorPointer" onClick={e=>this.guardarIconoSelect(e.target.src)} src={config.baseUrl + "/escenarios/prender.png"}></ImageIcono>
                    <ImageIcono className="cursorPointer" onClick={e=>this.guardarIconoSelect(e.target.src)} src={config.baseUrl + "/escenarios/apagar.png"}></ImageIcono>
                    <ImageIcono className="cursorPointer" onClick={e=>this.guardarIconoSelect(e.target.src)} src={config.baseUrl + "/escenarios/duranteon.png"}></ImageIcono>
                    <ImageIcono className="cursorPointer" onClick={e=>this.guardarIconoSelect(e.target.src)} src={config.baseUrl + "/escenarios/duranteoff.png"}></ImageIcono>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose} variant="primary">
                            CANCELAR
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.modalShow} onHide={this.handleClose} >
                    <Modal.Header>
                        <Modal.Title> {this.state.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modalBody}
                    </Modal.Body>
                    <Modal.Footer>
                        {this.modalDisplay()}
                    </Modal.Footer>
                </Modal>


                <Edicion>
                    <button type="button" className="btn-fill btn btn-grey btn-sm" style={{ display: !this.state.edit ? null : "none" }} onClick={() => this.setState({ edit: !this.state.edit })} title="Modo edición"><i className="fas fa-cog"></i></button>

                    <button type="button" className="btn-fill btn btn-info btn-sm" style={{ display: this.state.edit ? null : "none" }} onClick={() => this.limpiarEditar()} title="Nuevo escenario"><i className="fas fa-plus"></i></button>
                    <button type="button" className="btn-fill btn btn-danger btn-sm" style={{ display: this.state.edit ? null : "none" }} onClick={() => this.setState({ edit: false })} title="Cerrar modo edición"><i className="fas fa-times"></i></button>
                </Edicion>
                <List>
                    {this.state.scenes && this.state.scenes.length>0?
                    this.state.scenes.map(scene => (
                        <Item
                            selected={this.state.selectedId == scene._id}
                            key={scene._id}
                            onClick={() => (this.getSelectedScene(scene))}

                        >
                            <div>
                                <Image src={scene._imageUrl} title={scene._description} />
                                <EditIcon
                                    selected={this.state.edit}
                                    onClick={() => this.getSelectedScene(scene)}
                                >
                                    <i className="fas fa-edit"></i>
                                </EditIcon>
                                <DeleteIcon
                                    selected={this.state.edit}
                                    onClick={() => this.setEliminarEscenaSeleccionada(scene)}
                                >
                                    <i className="fas fa-trash"></i>
                                </DeleteIcon>

                            </div>
                            <Name>{scene._name}</Name>
                        </Item>
                    )):null}
                </List>

                {newScene}
                {renderTree}
            </Container>
        );
    };
}

const Container = styled.div`
  width: 100%;
  height: 100%;  
`;

const List = styled.div`

@media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  }
@media (max-width: 1100px) {
  grid-template-columns: 1fr 1fr 1fr 1fr ;
}
@media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr ;
}

@media (max-width: 425px) {
    grid-template-columns: 1fr 1fr ;
  }

 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: end;
  padding: 16px 16px;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  color:black;
`;

const Edicion = styled.div`
width: 100%;
height: 100%;
flex-direction:row;
justify-content: flex-end;
align-items: center;
padding: 16px 16px;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  align-self: center;
display:flex;
`;

const DivEscena = styled.div`
width: 100%;
height: 100%;
flex-direction:columns;
justify-content: center;
display:inline-grid;
`;

const Row = styled.div`
display: inline-flex;
flex-direction: row;
justify-content: center;
padding:5px
`;


const Item = styled.div`
 
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  transition: 0.3s;
  background: rgba(50, 100, 100, 0);
  cursor:pointer;
  &:hover {
    background: rgba(50, 100, 100, 0.1);

  }
  ${props =>
        props.selected &&
        `
  background:rgba(50,100,100,0.3);
  `}
`;


const EditIcon = styled.button`
position: absolute;
margin-top: 5px;
margin-left: -100px;
display:none;
border-radius:3px;
&:hover {
    
    color:#5bc0de;

  }
${props =>
        props.selected &&
        `
display:inline;
`}

`;

const DeleteIcon = styled.button`
position: absolute;
margin-top: 5px;
margin-left: -53px;
display:none;
border-radius:3px;
&:hover {
    
    color:#FF4A55;

  }
${props =>
        props.selected &&
        `
display:inline;
`}
`;

const Botones = styled.button`
    max-width:50px;
    margin:5px;
`;
const BotonesIconos = styled.button`
color: #7a7a7a;
background-color: #ffffff;
font-weight: 600;
    margin:5px;
`;

const Image = styled.img`
  width: 100%;
  padding: 0px 16px;
  object-fit: cover;
  
`;

const Name = styled.p`
  color: darkslategray;
  font-size: 14px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  padding:6px;
`;

const Label = styled.p`
  color: darkslategray;
  font-size: 14px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  padding:10px;
`;

const InputEscena = styled.input`
  text-transform: uppercase;
`;

const ImageIcono = styled.img`
  width: 4.7rem;
  height: 4.7rem;
  margin-right: 1rem;
`;

export default SceneList;